import { Box, Flex, Title } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomLoader, CustomSelectOpen, PersonnelCardAccordion } from "src/components";
import { officesIds } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { useGetAllMapQuery, useSearchCardMutation } from "src/store";
import { getJsonFiles } from "src/utils";

export const ReferenceModule = () => {
    const { t, i18n } = useTranslation();
    const [personnelCards, setPersonnelCards] = useState<CardType[]>([]);
    const [searchCards, { isLoading }] = useSearchCardMutation();
    const { data: regions } = useGetAllMapQuery({});
    const [activeOffice, setActiveOffice] = useState<string>();
    const [offices, setOffices] = useState<ProsecutorOfficeNameType[]>();
    const officeIds: string[] =
        offices?.map((el) => getTitleByLanguage(el)) || [];

    useEffect(() => {
        getJsonFiles("prosecutor-offices").then((data) => {
            const response = data.filter(
                (el: ProsecutorOfficeNameType) => el.id !== officesIds.managers
            );
            setOffices(response);
            setActiveOffice(getTitleByLanguage(response[0]));
        });
    }, [i18n.language]);

    const regionNames: string[] =
        useMemo(
            () =>
                regions?.map((el) =>
                    getTitleByLanguage({
                        titleKg: el.cityOrRegionNameKg,
                        titleRu: el.cityOrRegionNameRu,
                    })
                ),
            [regions]
        ) || [];

    const [activeRegion, setActiveRegion] = useState<string | null>(
        t("management.territory-offices")
    );

    useEffect(() => {
        if (activeRegion === t("management.territory-offices")) {
            const id = offices?.find(
                (el) =>
                    el.titleRu === activeOffice || el.titleKg === activeOffice
            )?.id;
            getPersonnelCards(null, id);
        } else {
            const id = regions?.find(
                (el) =>
                    el.cityOrRegionNameRu === activeRegion ||
                    el.cityOrRegionNameKg === activeRegion
            )?.id;
            getPersonnelCards(id, null);
        }
    }, [activeRegion, activeOffice]);

    const getPersonnelCards = useCallback(
        async (mapPointId?: number | null, officeId?: number | null) => {
            if (mapPointId || officeId) {
                try {
                    const response = await searchCards({
                        filter: {
                            active: true,
                            mapPointId: mapPointId,
                            prosecutorOfficeId: officeId,
                            acceptsRequests: false,
                        },
                        pageRequest: { limit: 100, page: 0 },
                        sorting: {
                            sortBy: "IMPORTANCE",
                        },
                    });
                    if ("data" in response) {
                        setPersonnelCards(response.data.content || null);
                    }
                } catch {
                    console.error("error");
                }
            }
        },
        [activeRegion, i18n.language, activeOffice]
    );

    return (
        <Box mt={{ base: -24, lg: 0 }} mih="100vh">
            <Title
                mb={{ base: 18, lg: 30 }}
                c="primaryDark"
                fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
            >
                {t("links.reference")}
            </Title>
            <Flex wrap="wrap" gap={16}>
                <CustomSelectOpen
                    width={340}
                    data={officeIds}
                    value={activeOffice || ""}
                    onChange={setActiveOffice}
                    bg="primaryDark"
                    color="primary"
                    checkedIcon={false}
                    dropdownColor="primary"
                />
                <CustomSelectOpen
                    width={340}
                    data={[t("management.territory-offices"), ...regionNames]}
                    value={activeRegion || ""}
                    onChange={setActiveRegion}
                    bg="primary"
                    color="primaryDark"
                    defaultValue={t("management.territory-offices")}
                    checkedIcon={false}
                    dropdownColor="primaryDark  "
                />
            </Flex>
            <Flex my={40} direction="column" gap={24}>
                {isLoading ?
                    <Box pos="relative" w="100%" mih="40vh" h="100%">
                        <CustomLoader />
                    </Box> : personnelCards?.map((item) => (
                        <PersonnelCardAccordion key={item.id} person={item} />
                    ))}
            </Flex>
        </Box>
    );
};
