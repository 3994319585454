// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_accordion_control__Phxmx {
  padding: 10px 0;
  border-bottom: 1px solid var(--borders) !important;
}
.styles_accordion_control__Phxmx:hover .styles_control_title__N2fOz {
  color: var(--secondary-dark) !important;
}
.styles_accordion_control__Phxmx:hover svg {
  stroke: var(--secondary-dark);
}`, "",{"version":3,"sources":["webpack://./src/components/reception-time/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kDAAA;AACJ;AACQ;EACI,uCAAA;AACZ;AACQ;EACI,6BAAA;AACZ","sourcesContent":[".accordion_control {\n    padding: 10px 0;\n    border-bottom: 1px solid var(--borders) !important;\n    &:hover {\n        .control_title {\n            color: var(--secondary-dark) !important;\n        }\n        svg {\n            stroke: var(--secondary-dark);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion_control": `styles_accordion_control__Phxmx`,
	"control_title": `styles_control_title__N2fOz`
};
export default ___CSS_LOADER_EXPORT___;
