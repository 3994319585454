// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_other_news__6mcIG {
  display: flex;
  width: 60%;
  max-height: 115vh;
  justify-content: center;
}

@media (max-width: 1200px) {
  .styles_other_news__6mcIG {
    display: flex;
    width: 60%;
    max-height: 115vh;
    justify-content: center;
  }
  .styles_other_news__6mcIG .styles_news_item__4yaJQ {
    margin-top: 25px;
    max-width: 80%;
  }
}
@media (max-width: 815px) {
  .styles_other_news__6mcIG {
    max-height: 300px;
    width: 100%;
  }
  .styles_other_news__6mcIG .styles_news_item__4yaJQ {
    min-width: 100%;
  }
}
@media (max-width: 768px) {
  .styles_other_news__6mcIG {
    max-height: 300px;
    width: 94vw;
  }
  .styles_other_news__6mcIG .styles_news_item__4yaJQ {
    min-width: 94vw !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/open-part/news/panel-news/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,UAAA;EACA,iBAAA;EACA,uBAAA;AACJ;;AAEA;EAEI;IACI,aAAA;IACA,UAAA;IACA,iBAAA;IACA,uBAAA;EAAN;EACM;IACI,gBAAA;IACA,cAAA;EACV;AACF;AAEA;EACI;IACI,iBAAA;IACA,WAAA;EAAN;EACM;IACI,eAAA;EACV;AACF;AAEA;EACI;IACI,iBAAA;IACA,WAAA;EAAN;EACM;IACI,0BAAA;EACV;AACF","sourcesContent":[".other_news {\n    display: flex;\n    width: 60%;\n    max-height: 115vh;\n    justify-content: center;\n}\n\n@media (max-width: 1200px) {\n\n    .other_news {\n        display: flex;\n        width: 60%;\n        max-height: 115vh;\n        justify-content: center;\n        & .news_item {\n            margin-top: 25px;\n            max-width: 80%;\n        }\n    }\n}\n@media (max-width: 815px) {\n    .other_news {\n        max-height: 300px;\n        width: 100%;\n        & .news_item {\n            min-width: 100%;\n        }\n    }\n}\n@media (max-width: 768px) {\n    .other_news {\n        max-height: 300px;\n        width: 94vw;\n        & .news_item {\n            min-width: 94vw !important;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"other_news": `styles_other_news__6mcIG`,
	"news_item": `styles_news_item__4yaJQ`
};
export default ___CSS_LOADER_EXPORT___;
