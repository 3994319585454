// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_textContainer__mA3OV {
  text-align: justify;
  font-weight: normal;
}
.styles_textContainer__mA3OV .styles_text__GyH6L {
  font-weight: normal;
  line-height: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs-content/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,mBAAA;AACJ;AAAI;EACI,mBAAA;EACA,mBAAA;AAER","sourcesContent":[".textContainer {\n    text-align: justify;\n    font-weight: normal;\n    & .text {\n        font-weight: normal;\n        line-height: 1.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textContainer": `styles_textContainer__mA3OV`,
	"text": `styles_text__GyH6L`
};
export default ___CSS_LOADER_EXPORT___;
