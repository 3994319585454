import { List, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { personnelSectionType } from "src/types";

interface PersonnelListProps {
    data: personnelSectionType | null;
}

export const ListModule = ({ data }: PersonnelListProps) => {
    const { i18n } = useTranslation();
    return (
        <List type="ordered">
            {data?.content?.map((el) => (
                <List.Item key={el.titleRu}>
                    <Title fw={400} order={4} fz={{ base: 16, md: 20 }} lh={1}>
                        {getTitleByLanguage(el)}
                    </Title>
                    <List>
                        {i18n.language === "ru"
                            ? el.listRu?.map((item, idx) => (
                                <List.Item
                                    key={idx}
                                    my={5}
                                    fz={{ base: 14, md: 18 }}
                                >
                                    {item}
                                </List.Item>
                            ))
                            : el.listKg?.map((item, idx) => (
                                <List.Item
                                    key={idx}
                                    my={5}
                                    fz={{ base: 14, md: 18 }}
                                >
                                    {item}
                                </List.Item>
                            ))}
                    </List>
                </List.Item>
            ))}
        </List>
    );
};
