// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_statistic__WDdUU {
  padding: 20px 40px;
}
.styles_statistic__WDdUU .styles_progress__dzerF {
  width: -moz-fit-content;
  width: fit-content;
}
.styles_statistic__WDdUU .styles_progress_item__EPVeq {
  cursor: pointer;
  padding: 0 5px;
  transition: 350ms;
}
.styles_statistic__WDdUU .styles_progress_item__EPVeq:hover {
  background: rgba(0, 0, 0, 0.0392156863);
}

@media (max-width: 1200px) {
  .styles_statistic__WDdUU {
    flex-direction: column;
    padding: 90px 20px;
  }
}
@media (max-width: 992px) {
  .styles_statistic__WDdUU {
    padding: 10px;
  }
  .styles_statistic__WDdUU .styles_progress__dzerF {
    width: -moz-fit-content;
    width: fit-content;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/statistic/top/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,uBAAA;EAAA,kBAAA;AAER;AAAI;EACI,eAAA;EACA,cAAA;EACA,iBAAA;AAER;AAAI;EACI,uCAAA;AAER;;AAEA;EACI;IACI,sBAAA;IACA,kBAAA;EACN;AACF;AACA;EACI;IACI,aAAA;EACN;EAAM;IACI,uBAAA;IAAA,kBAAA;EAEV;AACF","sourcesContent":[".statistic {\n    padding: 20px 40px;\n    .progress {\n        width: fit-content;\n    }\n    .progress_item {\n        cursor: pointer;\n        padding: 0 5px;\n        transition: 350ms;\n    }\n    .progress_item:hover {\n        background: #0000000a;\n    }\n}\n\n@media (max-width: 1200px) {\n    .statistic {\n        flex-direction: column;\n        padding: 90px 20px;\n    }\n}\n@media (max-width: 992px) {\n    .statistic {\n        padding: 10px;\n        .progress {\n            width: fit-content;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic": `styles_statistic__WDdUU`,
	"progress": `styles_progress__dzerF`,
	"progress_item": `styles_progress_item__EPVeq`
};
export default ___CSS_LOADER_EXPORT___;
