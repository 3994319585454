interface TreeLineProps {
    x1: number | null;
    y1: number | null;
    x2: number | null;
    y2: number | null;
    x3?: number | null;
    y3?: number | null;
    x4?: number | null;
    y4?: number | null;
    color?: string;
    strokeWidth?: number;
}

export const TreeLine = ({
    x1,
    y1,
    x2,
    y2,
    x3,
    y3,
    x4,
    y4,
    color = "black",
    strokeWidth = 2,
}: TreeLineProps) => {
    const x = x4 || x3 || x2 || 0;
    const y = y4 || y3 || y2 || 0;
    return (
        <svg
            style={{ position: "absolute", top: "0", left: "0" }}
            width="100%"
            height="100%"
        >
            <line
                y1={y1 || 0}
                x2={x2 || 0}
                y2={y2 || 0}
                x1={x1 || 0}
                stroke={color}
                strokeWidth={strokeWidth}
            />
            {x3 && y3 && (
                <line
                    x1={x2 || 0}
                    y1={y2 || 0}
                    x2={x3}
                    y2={y3}
                    stroke={color}
                    strokeWidth={strokeWidth}
                />
            )}
            {x4 && y4 && (
                <line
                    x1={x3 || 0}
                    y1={y3 || 0}
                    x2={x4}
                    y2={y4}
                    stroke={color}
                    strokeWidth={strokeWidth}
                />
            )}
            {
                <circle
                    cx={x}
                    cy={y}
                    r="6"
                    stroke="var(--secondary)"
                    strokeWidth="3"
                    fill="var(--secondary)"
                />
            }
        </svg>
    );
};
