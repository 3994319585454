import {
    Container,
    Grid,
    MultiSelect,
    Paper
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    ButtonGroup,
    CustomInputPassword,
    CustomInputText
} from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useCreateUserMutation, useGetPermissionsQuery, useGetRolesQuery, useUpdateUserMutation } from "src/store/reducers";
import { notify } from "src/utils";

type Props = {
    data?: IUser;
};

export const UserForm = ({ data }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const { data: roles } = useGetRolesQuery({})
    const { data: permissions } = useGetPermissionsQuery({})

    const rolesSelect = roles?.map(el => ({
        label: getTitleByLanguage(el),
        value: `${el.id}`
    }))
    const permissionsSelect = permissions?.map(el => ({
        label: getTitleByLanguage(el),
        value: `${el.id}`
    }))


    const form = useForm<UserForm>({
        initialValues: {
            inn: data?.inn || "",
            name: data?.name || "",
            patronymic: data?.patronymic || "",
            surname: data?.surname || "",
            permissionIds: data?.permissions?.map(el => el.id) || [],
            rankId: data?.rank?.id || null,
            roleIds: data?.roles?.map(el => el.id) || [],
            login: data?.login || "",
            password: "",
        },
        validate: {},
    });


    const handleSubmit = async (values: UserForm) => {
        if (data) {
            try {
                await updateUser({ id: data?.id, body: values }).unwrap();
                notify(true, t("notify.updated"));
                navigate("/users");
            } catch {
                notify(false);
            }
        } else {
            try {
                await createUser(values);
                notify(true, t("notify.saved"));
                form.reset();
                navigate("/users");
            } catch {
                notify(false);
            }
        }
    };

    return (
        <Container size="xl" w="100%" p={0}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Paper shadow="sm" p={{ base: "sm", md: "xl" }}>
                    <Grid>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("name")}
                                label={t`form.label.name`}
                                required
                                error={form.errors.name}
                                formData={form.values.name}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("patronymic")}
                                label={t`form.label.patronymic`}
                                error={form.errors.patronymic}
                                formData={form.values.patronymic}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                {...form.getInputProps("surname")}
                                label={t`form.label.surname`}
                                error={form.errors.surname}
                                formData={form.values.surname}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomInputText
                                max={14}
                                {...form.getInputProps("inn")}
                                label={t`form.label.inn`}
                                error={form.errors.inn}
                                formData={form.values.inn}
                                required
                            />
                        </Grid.Col>

                        <Grid.Col span={12}>
                            <CustomInputText
                                max={14}
                                {...form.getInputProps("login")}
                                label={t`form.label.login`}
                                error={form.errors.login}
                                formData={form.values.login}
                            />
                        </Grid.Col>
                        {!data?.id && <Grid.Col span={12}>
                            <CustomInputPassword
                                {...form.getInputProps("password")}
                                label={t`form.label.password`}
                                error={form.errors.password}
                                formData={form.values.password}
                                required
                            />
                        </Grid.Col>}

                        <Grid.Col span={{ base: 12, md: 6 }} >
                            <MultiSelect
                                styles={{
                                    pill: {
                                        color: "white"
                                    }
                                }}
                                color="white"
                                placeholder={t`form.label.permissions`}
                                onChange={(el) => {
                                    form.setFieldValue("permissionIds", el ? el.map(el => parseInt(el)) : [])
                                }}
                                value={form.values.permissionIds?.map(el => `${el}`)}
                                data={permissionsSelect || []}
                            />
                        </Grid.Col>


                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <MultiSelect
                                styles={{
                                    pill: {
                                        color: "white"
                                    }
                                }}
                                color="white"
                                placeholder={t`form.label.roles`}
                                onChange={(el) => {
                                    form.setFieldValue("roleIds", el ? el.map(el => parseInt(el)) : [])
                                }}
                                value={form.values.roleIds?.map(el => `${el}`)}
                                data={rolesSelect || []}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <ButtonGroup
                                onClickCancel={() =>
                                    navigate(`/users/${i18n.language}`)
                                }
                                type="submit"
                            />
                        </Grid.Col>
                    </Grid>
                </Paper>
            </form>
        </Container>
    );
};
