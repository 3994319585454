// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_news_top__t2q0S .styles_calendar_btn__nphOW {
  display: none;
  background: #000;
}
.styles_news_top__t2q0S .styles_all_news_btn__\\+uf\\+j {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  box-shadow: 0px 0px 33px -3px rgba(34, 60, 80, 0.2);
}

@media (max-width: 1200px) {
  .styles_news_top__t2q0S .styles_calendar_btn__nphOW {
    display: block;
  }
  .styles_calendar__YC5Sh {
    position: absolute;
    top: 330px;
    right: 0;
    z-index: 10;
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/open-part/news/styles.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,gBAAA;AAAR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EAGA,mDAAA;AAAR;;AAIA;EAEQ;IACI,cAAA;EAFV;EAKE;IACI,kBAAA;IACA,UAAA;IACA,QAAA;IACA,WAAA;EAHN;AACF","sourcesContent":[".news_top {\n    .calendar_btn {\n        display: none;\n        background: #000;\n    }\n    .all_news_btn {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border-radius: 2px;\n        -webkit-box-shadow: 0px 0px 33px -3px rgba(34, 60, 80, 0.2);\n        -moz-box-shadow: 0px 0px 33px -3px rgba(34, 60, 80, 0.2);\n        box-shadow: 0px 0px 33px -3px rgba(34, 60, 80, 0.2);\n    }\n}\n\n@media (max-width: 1200px) {\n    .news_top {\n        .calendar_btn {\n            display: block;\n        }\n    }\n    .calendar {\n        position: absolute;\n        top: 330px;\n        right: 0;\n        z-index: 10;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"news_top": `styles_news_top__t2q0S`,
	"calendar_btn": `styles_calendar_btn__nphOW`,
	"all_news_btn": `styles_all_news_btn__+uf+j`,
	"calendar": `styles_calendar__YC5Sh`
};
export default ___CSS_LOADER_EXPORT___;
