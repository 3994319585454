import {
    Card,
    Grid,
    MantineFontSize,
    MantineStyleProp,
    StyleProp,
    Title,
} from "@mantine/core";
import { RefObject, useEffect, useRef } from "react";
export type ColSpan = number | "auto" | "content";

interface StructureCardProps {
    title: string;
    size: StyleProp<MantineFontSize | number | (string & {})>;
    style?: MantineStyleProp;
    span?: StyleProp<ColSpan>;
    height?: StyleProp<React.CSSProperties["height"]>;
    setCoordinate?: (x: number, y: number) => void;
    setLikeParent?: (x: number, y: number) => void;
}

export const StructureCard = ({
    title,
    size,
    style = { background: "rgba(var(--primary-dark), 1)" },
    span,
    height = 160,
    setCoordinate,
    setLikeParent,
}: StructureCardProps) => {
    const elementRef: RefObject<HTMLDivElement> = useRef(null);

    useEffect(() => {
        const element = elementRef.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const x = rect.left + rect.width / 2 + window.scrollX;
            const y = rect.top + rect.height / 2 + window.scrollY;

            if (setCoordinate) {
                setCoordinate(x, y);
            }
            if (setLikeParent) {
                setLikeParent(x, y);
            }
        }
    }, [setCoordinate, setLikeParent]);

    return (
        <Grid.Col
            h={height}
            mb={{ base: 20, md: 30 }}
            style={{ position: "relative", zIndex: "1" }}
            span={span}
        >
            <Card
                ref={elementRef}
                w="100%"
                h="100%"
                style={{ ...style, align: "center", justifyContent: "center" }}
                display="flex"
            >
                <Title ta="center" fz={size} c="secondary">
                    {title}
                </Title>
            </Card>
        </Grid.Col>
    );
};
