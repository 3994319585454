import { ActionIcon, Center, CopyButton, Tooltip } from "@mantine/core";
import { IconCheck, IconLink } from "@tabler/icons-react";

interface IProps {
    white?: boolean;
    copy?: boolean;
}

export const Links = ({ white = true, copy = false }: IProps) => {
    const currentUrl = window.location.href;
    return (
        <Center style={{ gap: "18px" }}>
            {copy && (
                <CopyButton value={currentUrl} timeout={2000}>
                    {({ copied, copy }) => (
                        <Tooltip
                            label={copied ? "Copied" : "Copy"}
                            withArrow
                            position="right"
                        >
                            <ActionIcon
                                color={white ? "gray" : "dark"}
                                onClick={copy}
                            >
                                {copied ? <IconCheck /> : <IconLink />}
                            </ActionIcon>
                        </Tooltip>
                    )}
                </CopyButton>
            )}
        </Center>
    );
};
