import { Flex, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { StatisticBottomSection } from "./bottom/index.";
import { StatisticSliderSection } from "./top";

export const StatisticSection = () => {
    const { t, i18n } = useTranslation();

    return (
        <Flex direction="column" mb={32}>
            <a href={`/statistics/${i18n.language}`}>
                <Title c="secondaryDark" mb={24} fz={{ base: 18, sm: 24, md: 30 }}>
                    {t("titles.statistic-2023")}
                </Title>
            </a>
            <StatisticSliderSection />
            <StatisticBottomSection />
        </Flex>
    );
};
