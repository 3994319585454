import ReactApexChart from "react-apexcharts";
import { VALUES_SHOW_TYPES } from "src/constants";
import { generatePieOptions } from "../options/generate-pie-options";

export const PieChart = ({ data }) => {
    const withPercentage =
        data?.textEn !== VALUES_SHOW_TYPES.WITHOUT_PERCENTAGE;
    const { options } = generatePieOptions(data, withPercentage);

    return (
        <ReactApexChart
            options={options}
            series={options.series}
            type="pie"
            width="100%"
            height={450}
        />
    );
};
