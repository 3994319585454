import { Box, Button, Flex, Grid, Image, Paper, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";
interface Props {
    data:
    | {
        title: string;
        phones: string[];
    }[]
    | null;
}

const videoLinks = [
    {
        titleRu: "Регистрация проверок субъектов проводимых правоохранительными и налоговой службы органами",
        titleKg: "Укук коргоо жана салык органдары тарабынан жүргүзүлгөн субъекттерди текшерүүлөрдү каттоо",
        link: "https://www.youtube.com/embed/-khyFWPBgvQ"
    },
    {
        titleRu: "Проводятся правоохранительными органами и органами налоговой службы в отношении субъектов предпринимательства",
        titleKg: "Укук коргоо органдары жана салык кызматынын органдары тарабынан ишкердик субъекттерге жүргүзүлүүчү",
        link: "https://www.youtube.com/embed/tBmHJD05ILY"
    },
    {
        titleRu: "Аззамбек Жээнбай уулу",
        titleKg: "Аззамбек Жээнбай уулу",
        link: "https://www.youtube.com/embed/xPoP8-fR5-E"
    },
    {
        titleRu: "Гульнара Ускенбаева",
        titleKg: "Гульнара Ускенбаева",
        link: "https://www.youtube.com/embed/sQtxmEDRBBg"
    },
    {
        titleRu: "Калыгул Салиев",
        titleKg: "Калыгул Салиев",
        link: "https://www.youtube.com/embed/_at7PQEResI"
    },
    {
        titleRu: "Нурлан Мусуралиев",
        titleKg: "Нурлан Мусуралиев",
        link: "https://www.youtube.com/embed/U5BbpE3MTDg"
    },
];

export const ProtectBussinessModule = ({ data }: Props) => {
    const { t } = useTranslation()
    return (
        <Flex direction="column" gap={50}>
            {data ? (
                <Grid gutter={30}>
                    {data.map((el) => (
                        <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
                            <Flex
                                key={el.title}
                                direction="column"
                                align="start"
                                gap={0}
                            >
                                <Title
                                    fw={400}
                                    c="primaryDark"
                                    fz={{ base: 18, md: 20 }}
                                >
                                    {el.title}
                                </Title>
                                <Flex align="center" justify="center" gap={10}>
                                    <Image
                                        w="100%"
                                        src="/icons/phone-call.svg"
                                    />
                                    <Flex wrap="wrap">
                                        {el.phones?.map((el) => (
                                            <Text
                                                mr={10}
                                                key={el}
                                                c="secondaryDark"
                                                fw={500}
                                                fz={{ base: 16, md: 18 }}
                                            >
                                                {el};
                                            </Text>
                                        ))}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Grid.Col>
                    ))}
                </Grid>
            ) : (
                <Grid justify="center" gutter={{ base: 12, md: 24 }}>
                    <Grid.Col mb={32} span={12}>
                        <Title lh={1.3} c="primaryDark" fz={{ base: 18, xs: 24 }}>
                            {t("statics.protect-bussiness-tab-3")}
                        </Title>
                        <Flex wrap="wrap" gap={{ base: 16, sm: 32 }}>
                            <Flex display={{ base: "flex", sm: "none" }} gap={10} wrap="wrap">
                                <Button
                                    component={Link}
                                    target="_blank" to="https://play.google.com/store/apps/details?id=gov.ukuk.rpspexpo"
                                    bg="primaryDark" h="auto">
                                    <Flex align="center" gap={10}>
                                        <Text fw={500} c="primary" fz={16}>
                                            {t("titles.android-app")}
                                        </Text>
                                        <Image w={24} h={24} style={{ objectFit: "contain" }} src="/icons/android.svg" />
                                    </Flex>
                                </Button>
                                <Button
                                    component={Link}
                                    target="_blank"
                                    to="https://apps.apple.com/kg/app/%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81%D1%82%D0%B8-%D0%BA%D0%BE%D1%80%D0%B3%D0%BE%D0%BE/id6449217783" bg="primaryDark" h="auto">
                                    <Flex align="center" gap={10}>
                                        <Text fw={500} c="primary" fz={16}>
                                            {t("titles.ios-app")}
                                        </Text>
                                        <Image w={24} h={24} style={{ objectFit: "contain" }} src="/icons/apple.svg" />
                                    </Flex>
                                </Button>
                            </Flex>
                            <Flex display={{ base: "none", sm: "flex" }} direction="column" justify="center" align="center">
                                <Text mb={12} fw={500} c="primaryDark" fz={18}>
                                    {t("titles.android-app")}
                                </Text>
                                <Image maw={160} src="/assets/mobile-app-android.jpg" />
                            </Flex>
                            <Flex display={{ base: "none", sm: "flex" }} direction="column" justify="center" align="center">
                                <Text mb={12} fw={500} c="primaryDark" fz={18}>
                                    {t("titles.ios-app")}
                                </Text>
                                <Image maw={160} src="/assets/mobile-app-ios.jpg" />
                            </Flex>
                        </Flex>
                    </Grid.Col>
                    {videoLinks.map((el) => (
                        <Grid.Col
                            key={el.link}
                            maw={{ base: "500", lg: "none" }}
                            span={{ base: 12, md: 6 }}
                        >
                            <Paper p={8}>
                                <Box mb={10} h={{ base: 280, xs: 350, xl: 450 }}>
                                    <iframe
                                        title={getTitleByLanguage(el)}
                                        width="100%"
                                        height="100%"
                                        src={el.link}
                                        frameBorder="none"
                                        allowFullScreen
                                    ></iframe>
                                </Box>
                                <Text fw={500} fz={{ base: 16, md: 18 }} c="primaryDark">
                                    {getTitleByLanguage(el)}
                                </Text>
                            </Paper>
                        </Grid.Col>
                    ))}
                </Grid>
            )}
        </Flex>
    );
};
