import { Box, List, Paper } from "@mantine/core";
import { CustomLoader, TextInfo } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetUserQuery } from "src/store";

type Props = {
    newsId: string;
};

export const UserDetails = ({ newsId }: Props) => {

    const { data, isLoading } = useGetUserQuery(newsId, {
        refetchOnMountOrArgChange: true,
    });

    const organCard = [
        {
            label: "ФИО",
            value: data?.fullName,
        },
        {
            label: "Логин",
            value: data?.login,
        },
        {
            label: "ИНН",
            value: data?.inn,
        },
        {
            label: "Доступы",
            value: <List>
                {data?.permissions?.map(el => (
                    <List.Item key={el?.id}>
                        {getTitleByLanguage(el)}
                    </List.Item>
                ))}
            </List>,
        },
        {
            label: "Роль",
            value: <List>
                {data?.roles?.map(el => (
                    <List.Item key={el?.id}>
                        {getTitleByLanguage(el)}
                    </List.Item>
                ))}
            </List>,
        },
    ];

    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <TextInfo
                        data={organCard}
                        display={true}
                        spanFirst={4}
                        spanTwo={8}
                    />
                </Paper>
            }
        </Paper>
    );
};
