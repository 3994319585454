export const nowDate = new Date();
export * from "./diagram";

export const NEWS_100_YEAR_ID = 80

export const regionsId = {
    bishkek: 1,
    chuy: 2, //
    talas: 11, //
    ysykKol: 3,
    naryn: 5, //
    jalalAbad: 6, //
    batken: 8, //
    osh: 7, //
    oshCity: 12, //
};

export const IS_MINUS_ONE = -1;

export const genProsecutorId = 2;

export const defaultMapImportance = 3;

export const officesIds = {
    managers: 2,
    militaryProkuratura: 180,
    organProkuratura: 4,
    transportProkuratura: 5,
};

export const languages = [
    {
        value: "kg",
        label: "Кыргыз тили",
    },
    {
        value: "ru",
        label: "Русский язык",
    },
];

export const PageListPositions = {
    CARD: "CARD",
    ACCORDION: "ACCORDION",
    TEXT: "TEXT",
    TABS: "TABS",
    LIST: "LIST",
    DOCUMENT: "DOCUMENT",
};

export const baseUrl = process.env.REACT_APP_API_PROD || "https://prokuror.kg";
