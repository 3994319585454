import { Box, Flex, Grid, Paper, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import {
    CustomLoader, PersonnelCardAccordion,
    ProsecutorOfficeCardInfo, TextInfo
} from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useGetCardQuery } from "src/store";

type Props = {
    cardId: string;
};

export const CardInfoToAppealDetails = ({ cardId }: Props) => {
    const { t } = useTranslation();

    const { data: cardsData, isLoading } = useGetCardQuery(cardId, {
        refetchOnMountOrArgChange: true,
    });

    const actsCard = [
        {
            label: t`data.news.title`,
            value: getTitleByLanguage(cardsData?.fullName),
        },
    ];
    return (
        <Paper shadow="sm" p="xl">
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <Paper shadow="sm" p="xl">
                    <TextInfo
                        data={actsCard}
                        display={true}
                        spanFirst={4}
                        spanTwo={8}
                    />
                    <Box mt={30}>
                        <Text mb={20} ta="center">
                            Результат:
                        </Text>
                        {cardsData?.prosecutorOffice.titleRu ===
                            "Генеральная прокуратура КР" ? (
                            <Flex my={40} direction="column">
                                <PersonnelCardAccordion person={cardsData} />
                            </Flex>
                        ) : (
                            <Grid my={40} w="200%">
                                <ProsecutorOfficeCardInfo
                                    person={cardsData as CardType}
                                />
                            </Grid>
                        )}
                    </Box>
                </Paper>
            }
        </Paper>
    );
};
