import { Accordion, AccordionItem, Box, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { getJsonFiles } from "src/utils";

export const AppealRules = () => {
    const { t } = useTranslation();
    const [appealLawData, setAppealLawData] = useState<AppealLawType[]>();

    useEffect(() => {
        getJsonFiles("appeal-law").then((data) => {
            setAppealLawData(data);
        });
    }, []);
    return (
        <Box mih="100vh" maw={1500}>
            <Title
                mb={30}
                c="primaryDark"
                fz={{ base: 16, xs: 18, sm: 20, xl: 24 }}
            >
                {t("titles.appeal-rules-citizens")}
            </Title>
            <Text c="primaryDark" fz="xl" fw={500} mb={20}>
                {t("statics.appeal-laws")}
            </Text>
            <Text mb={80} c="primaryDark" fz="xl" fw={400} lh={1.4}>
                {t("statics.appeal-laws-description")}
            </Text>
            <Accordion>
                {appealLawData?.map((el) => <RuleItem key={el.id} item={el} />)}
            </Accordion>
        </Box>
    );
};

interface RuleItemProps {
    item: AppealLawType;
}

const RuleItem = ({ item }: RuleItemProps) => (
    <AccordionItem value={item.id.toString()}>
        <Accordion.Control>
            <Text fw={400} fz="lg" c="blueDark">
                {getTitleByLanguage(item)}
            </Text>
        </Accordion.Control>
        <Accordion.Panel>
            <Text c="blueDark" lh={2} style={{ whiteSpace: "pre-wrap" }}>
                {getTitleByLanguage({
                    titleRu: item.descriptionRu,
                    titleKg: item.descriptionKg,
                })}
            </Text>
        </Accordion.Panel>
    </AccordionItem>
);
