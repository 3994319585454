// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_addresses__C4L1D {
  width: 60%;
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi {
  border-bottom: 1px solid var(--borders) !important;
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi .styles_control_title__4ROxI {
  color: var(--primaryDark);
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi .styles_accordion_control__yJ2IE {
  padding: 0 !important;
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi .styles_accordion_icon__rLWli {
  border-radius: 50%;
  border: 1px solid gray;
  color: gray;
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi:hover .styles_control_title__4ROxI {
  color: rgba(var(--primary-dark), 1) !important;
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi:hover .styles_control_subtitle__DGi6q {
  color: rgba(var(--primary-dark), 1) !important;
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi:hover .styles_accordion_control__yJ2IE {
  background: none;
}
.styles_addresses__C4L1D .styles_contacts_accordion__-OCvF .styles_accordion_item__6eURi:hover .styles_accordion_icon__rLWli {
  color: rgba(var(--primary-dark), 1);
  border: 1px solid rgba(var(--primary-dark), 1);
}

@media (max-width: 1439px) {
  .styles_addresses__C4L1D {
    width: 100%;
  }
}
@media (max-width: 885px) {
  .styles_addresses__C4L1D {
    width: 100%;
    margin-top: -70px;
  }
}
@media (max-width: 542px) {
  .styles_addresses__C4L1D {
    margin-top: -130px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/contacts/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;AACQ;EACI,kDAAA;AACZ;AAAY;EACI,yBAAA;AAEhB;AAAY;EACI,qBAAA;AAEhB;AAAY;EACI,kBAAA;EACA,sBAAA;EACA,WAAA;AAEhB;AACgB;EACI,8CAAA;AACpB;AACgB;EACI,8CAAA;AACpB;AACgB;EACI,gBAAA;AACpB;AACgB;EACI,mCAAA;EACA,8CAAA;AACpB;;AAKA;EACI;IACI,WAAA;EAFN;AACF;AAKA;EACI;IACI,WAAA;IACA,iBAAA;EAHN;AACF;AAKA;EACI;IACI,kBAAA;EAHN;AACF","sourcesContent":[".addresses {\n    width: 60%;\n    .contacts_accordion {\n        .accordion_item {\n            border-bottom: 1px solid var(--borders) !important;\n            .control_title {\n                color: var(--primaryDark);\n            }\n            .accordion_control {\n                padding: 0 !important;\n            }\n            .accordion_icon {\n                border-radius: 50%;\n                border: 1px solid gray;\n                color: gray;\n            }\n            &:hover {\n                .control_title {\n                    color: rgba(var(--primary-dark), 1) !important;\n                }\n                .control_subtitle {\n                    color: rgba(var(--primary-dark), 1) !important;\n                }\n                .accordion_control {\n                    background: none;\n                }\n                .accordion_icon {\n                    color: rgba(var(--primary-dark), 1);\n                    border: 1px solid rgba(var(--primary-dark), 1);\n                }\n            }\n        }\n    }\n}\n@media (max-width: 1439px) {\n    .addresses {\n        width: 100%;\n    }\n}\n\n@media (max-width: 885px) {\n    .addresses {\n        width: 100%;\n        margin-top: -70px;\n    }\n}\n@media (max-width: 542px) {\n    .addresses {\n        margin-top: -130px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addresses": `styles_addresses__C4L1D`,
	"contacts_accordion": `styles_contacts_accordion__-OCvF`,
	"accordion_item": `styles_accordion_item__6eURi`,
	"control_title": `styles_control_title__4ROxI`,
	"accordion_control": `styles_accordion_control__yJ2IE`,
	"accordion_icon": `styles_accordion_icon__rLWli`,
	"control_subtitle": `styles_control_subtitle__DGi6q`
};
export default ___CSS_LOADER_EXPORT___;
