import { Title } from "@mantine/core";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useDeleteCardMutation } from "src/store";
import { HeadCell } from "src/types";
import { Path, notify } from "src/utils";

export const CardInfoTableToAppeal = ({
    filter,
}: {
    filter?: CardInfoFilterType;
}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [cardDelete] = useDeleteCardMutation();

    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await cardDelete(id);
            notify(true, t("notify.deleted"));
        } catch {
            notify(false);
        }
        setOpen(false);
    };

    const actions = (id: number) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/card_info_to_appeal/${id}/edit`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/card_info_to_appeal/${id}`,
        },
        {
            icon: IconTrash,
            title: t`actions.delete`,
            onClick: () => handleOpen(id),
        },
    ];

    const headCells: HeadCell<CardType>[] = [
        {
            label: t`form.label.importance`,
            render: (n) => n.importance,
        },
        {
            label: t`table.fullName`,
            render: (n) => getTitleByLanguage(n.fullName),
        },
        {
            label: t`table.position`,
            render: (n) => getTitleByLanguage(n.position),
        },
        {
            label: t`actions.title`,
            render: (n) => (
                <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
            ),
        },
    ];

    return (
        <>
            <AppTable<CardType>
                headCells={headCells}
                defaultFilter={{ acceptsRequests: true }}
                doubleClickPath={(n) =>
                    navigate(`/card_info_to_appeal/${n.id}/${i18n.language}`)
                }
                searchApiUrl={Path.CardInfo.search}
                extraFilter={filter}
            />
            <CustomModal
                labelSubmit={"delete"}
                open={open}
                handleClose={handleClose}
                onClick={handleDelete}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    style={{ textAlign: "center" }}
                >
                    {t`button.delete`}
                </Title>
            </CustomModal>
        </>
    );
};
