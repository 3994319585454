
interface ISelectArr {
    value: string;
    label: string;
}

export const VALUES_SHOW_TYPES = {
    WITH_PERCENTAGE: "WITH_PERCENTAGE",
    WITHOUT_PERCENTAGE: "WITHOUT_PERCENTAGE",
}

export const quartalTypeArray: (t: (a: string) => string) => ISelectArr[] = (t) => [
    {
        value: "FIRST",
        label: t("select.FIRST"),
    },
    {
        value: "SECOND",
        label: t("select.SECOND"),
    },
    {
        value: "THIRD",
        label: t("select.THIRD"),
    },
    {
        value: "FOURTH",
        label: t("select.FOURTH"),
    },
];

export const diagramInitialValues: DiagramRequestType = {
    id: 0,
    titleRu: "",
    titleEn: "true",
    titleKg: "",
    order: 1,
    description: "",
    diagramQuarter: "FIRST",
    year: 2023,
    diagramSpecificationId: null,
    type: "",
    toDeleteDiagramField: [],
    toDeleteDiagramFieldValue: [],
    textEn: "",
    textRu: "",
    textKg: "",
    totalPercentage: 1000,
    diagramTypeId: null,
    fieldRequest: [
        {
            id: Math.floor(Math.random()),
            order: 1,
            titleRu: "",
            code: "",
            titleKg: "",
            fieldValue: 0,
            valuesRequest: [],
        },
    ],
};


export const diagramChartTypes: (
    t: (s: string) => string,
    organPage: boolean
) => ISelectArr[] = (t, organPage) => {
    if (organPage) {
        return [
            {
                value: DIAGRAM_TYPES.PIE,
                label: t("select.PIE"),
            },
            {
                value: DIAGRAM_TYPES.VERTICAL_BAR,
                label: t("select.VERTICAL_BAR"),
            },
            {
                value: DIAGRAM_TYPES.HORIZONTAL_BAR,
                label: t("select.HORIZONTAL_BAR"),
            },
            {
                value: DIAGRAM_TYPES.RADIUS_PIE,
                label: t("select.RADIUS_PIE"),
            },
            {
                value: DIAGRAM_TYPES.CLASSIC,
                label: t("select.CLASSIC"),
            },
        ]
    } else {
        return [
            {
                value: DIAGRAM_TYPES.PIE,
                label: t("select.PIE"),
            },
            {
                value: DIAGRAM_TYPES.VERTICAL_BAR,
                label: t("select.VERTICAL_BAR"),
            },
            {
                value: DIAGRAM_TYPES.HORIZONTAL_BAR,
                label: t("select.HORIZONTAL_BAR"),
            },
        ]
    }

}

export const DIAGRAM_TYPES = {
    VERTICAL_BAR: "VERTICAL_BAR",
    HORIZONTAL_BAR: "HORIZONTAL_BAR",
    PIE: "PIE",
    RADIUS_PIE: "RADIUS_PIE",
    CLASSIC: "CLASSIC",
}



export const getStatisticQuarterData: (
    t: (a: string) => string
) => ISelectArr[] = (t) => {
    return [
        {
            value: "FIRST",
            label: t("select.FIRST"),
        },
        {
            value: "SECOND",
            label: t("select.SECOND"),
        },
        {
            value: "THIRD",
            label: t("select.THIRD"),
        },
        {
            value: "FOURTH",
            label: t("select.FOURTH"),
        },
    ];
};


export const progressSizez = [180, 220, 260, 300];
export const DIAGRAM_REFERENCE_TYPES = {
    STATISTIC: "statistic",
    MAIN_PAGE: "mainPage",
    ORGAN: "organ"
}

export const diagramColors = [
    "#B2F4FF",
    "#000F86",
    "#02C8FF",
    "#02A7FF",
    "#0174FF",
    "#0134FF",
    "#0217BE"
];

export const DIAGRAM_INFO_BLOCK_REFERENCE = {
    TOP_STATISTIC: "statistic1",
    BOTTOM_STATISTIC: "statistic2",

}