import { Accordion } from "@mantine/core";
import { personnelSectionType } from "src/types";
import { AccordionItem } from "./accordion-item";

interface PersonnelAccordionProps {
    data: personnelSectionType | null;
}

export const AccordionModule = ({ data }: PersonnelAccordionProps) => {
    return (
        <Accordion>
            {data?.content?.map((item) => (
                <AccordionItem key={item.titleRu} item={item} />
            ))}
        </Accordion>
    );
};
