import { List, Title } from "@mantine/core";
import { IconEye, IconPencil, IconRadioactiveOff } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppTable, CustomModal, MenuDropDown } from "src/components";
import { getTitleByLanguage } from "src/locales";
import { useDisableUserMutation } from "src/store";
import { HeadCell } from "src/types";
import { notify, Path } from "src/utils";

export const UserTable = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [userDisable] = useDisableUserMutation();
    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (id: number) => {
        setOpen(true);
        setId(id);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleUserDisable = async () => {
        if (id) {
            try {
                await userDisable(id).unwrap;
                notify(true, t("notify.disabled"));
            } catch (error) {
                notify();
            }
        }
        setOpen(false);
    }

    const actions = (data: IUser) => [
        {
            icon: IconPencil,
            title: t`actions.edit`,
            to: `/users/${data.id}/edit-user`,
        },
        {
            icon: IconEye,
            title: t`actions.view`,
            to: `/users/${data.id}`,
        },
        {
            icon: IconRadioactiveOff,
            title: t("button.disable"),
            onClick: () => handleOpen(data.id),
        },
    ];

    const headCells: HeadCell<IUser>[] = [
        {
            label: t`form.label.inn`,
            render: (n) => n.inn,
        },
        {
            label: t`table.fullName`,
            render: (n) => n.fullName,
        },
        {
            label: t`table.roles`,
            render: (n) => <List c="black" fz={13}>
                {n.roles?.map(el => (
                    <List.Item>
                        {getTitleByLanguage(el)}
                    </List.Item>
                ))}
            </List>,
        },
        {
            label: t`form.label.permissions`,
            render: (n) => <List c="black" fz={13}>
                {n.permissions?.map(el => (
                    <List.Item>
                        {getTitleByLanguage(el)}
                    </List.Item>
                ))}
            </List>,
        },
        {
            label: t`actions.title`,
            render: (n) => n.inn === "90000000000000" ? "Действия не доступны" : (
                <MenuDropDown label={t`actions.title`} item={actions(n)} />
            ),
        },
    ];

    return (
        <>
            <AppTable<IUser>
                headCells={headCells}
                doubleClickPath={(n) =>
                    navigate(`/users/${n.id}/${i18n.language}`)
                }
                defaultFilter={{
                    enabled: true
                }}
                searchApiUrl={Path.Users.search}
            />
            <CustomModal
                labelSubmit={"disable"}
                labelCancel={"cancel"}
                open={open}
                handleClose={handleClose}
                onClick={handleUserDisable}
            >
                <Title
                    order={3}
                    fw={400}
                    mt="5px"
                    mb="2rem"
                    ta="center"
                >
                    {t("button.disable")}?
                </Title>
            </CustomModal>
        </>
    );
};
