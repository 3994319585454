import { Card, Image, Text, TypographyStylesProvider } from "@mantine/core";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getTitleByLanguage } from "src/locales";

type CardWithTitlePropsType = {
    data: AlbumType;
    to: string;
};

export const CardWithTitle: FC<CardWithTitlePropsType> = ({ data, to }) => {
    const navigate = useNavigate();
    return (
        <Card
            shadow="sm"
            p="lg"
            radius="md"
            withBorder
            h={250}
            w={250}
            style={{
                cursor: "pointer",
                "&:hover": {
                    transition: "all 0.3s ease-in-out",
                    transform: "scale(1.05)",
                },
            }}
            onClick={() => navigate(to)}
        >
            <Card.Section>
                <Image
                    src={`data:image/webp;base64,${data?.photo}`}
                    height={160}
                    alt={"Ошибка загрузки фото"}
                    fit="cover"
                />
                <TypographyStylesProvider>
                    <Text
                        style={{
                            fontWeight: 600,
                            fontSize: 14,
                            textDecoration: "none",
                            height: 55,
                            display: "block",
                            overflowWrap: "break-word",
                            "&:hover": {
                                color: "#00498F",
                            },
                        }}
                    >
                        {getTitleByLanguage(data)}
                    </Text>
                </TypographyStylesProvider>
            </Card.Section>
        </Card>
    );
};
