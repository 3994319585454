// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_panel_cards_container__dt5d3 {
  flex-direction: column;
}
.styles_panel_cards_container__dt5d3 .styles_panel_card__lbAkG {
  min-width: 350px;
  padding: 40px 30px;
}

@media (max-width: 1200px) {
  .styles_panel_cards_container__dt5d3 {
    width: 90vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .styles_panel_cards_container__dt5d3 .styles_panel_card__lbAkG {
    width: 40%;
  }
}
@media (max-width: 992px) {
  .styles_panel_card__lbAkG {
    min-width: 200px;
    width: 100%;
    max-width: 550px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/panel-cards/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;AAAI;EACI,gBAAA;EACA,kBAAA;AAER;;AACA;EACI;IACI,WAAA;IACA,aAAA;IACA,mBAAA;IACA,eAAA;IACA,uBAAA;EAEN;EADM;IACI,UAAA;EAGV;AACF;AAAA;EACI;IACI,gBAAA;IACA,WAAA;IACA,gBAAA;EAEN;AACF","sourcesContent":[".panel_cards_container {\n    flex-direction: column;\n    .panel_card {\n        min-width: 350px;\n        padding: 40px 30px;\n    }\n}\n@media (max-width: 1200px) {\n    .panel_cards_container {\n        width: 90vw;\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n        justify-content: center;\n        .panel_card {\n            width: 40%;\n        }\n    }\n}\n@media (max-width: 992px) {\n    .panel_card {\n        min-width: 200px;\n        width: 100%;\n        max-width: 550px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel_cards_container": `styles_panel_cards_container__dt5d3`,
	"panel_card": `styles_panel_card__lbAkG`
};
export default ___CSS_LOADER_EXPORT___;
