import { ApexOptions } from "apexcharts";
import ru from "apexcharts/dist/locales/ru.json";
import { diagramColors } from "src/constants";
import { getTitleByLanguage } from "src/locales";

export const generateColOptions = (data: DataType, withPercentage = true) => {
    const title = getTitleByLanguage(data);
    const nameValue = data?.diagramFields
        .filter((el) => el.fieldValue)
        .map((field) => ({
            name: getTitleByLanguage(field),
            data: field.fieldValue,
        }));
    const defaultTotal = nameValue?.map(el => el.data)?.reduce((acc, el) => acc + el)

    const options: ApexOptions = {
        colors: diagramColors,
        series: [
            {
                name: title,
                data: nameValue.map((el) => el.data),
            },
        ],
        chart: {
            height: 800,
            type: "bar",
            background: "transparent",
            fontFamily: "Rubik, sans-serif",
            redrawOnParentResize: true,
            locales: [ru],
            defaultLocale: "ru",
            toolbar: {
                autoSelected: "selection",
                export: {
                    csv: {
                        filename: title,
                    },
                    png: {
                        filename: title,
                    },
                    svg: {
                        filename: title,
                    },
                },
            },
            zoom: {
                enabled: true,
                type: "x",
                autoScaleYaxis: false,
                zoomedArea: {
                    fill: {
                        color: "#90CAF9",
                        opacity: 0.4,
                    },
                    stroke: {
                        color: "#0D47A1",
                        opacity: 0.4,
                        width: 1,
                    },
                },
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 2,
                columnWidth: "60%",
                rangeBarOverlap: true,
                isFunnel3d: true,
                distributed: true,
            },
        },
        dataLabels: {
            // enabled: true,
            // distributed: false,
            textAnchor: "middle",
            // dropShadow: {
            //     blur: 0,
            //     color: "white",
            //     enabled: true,
            //     left: 3,
            //     opacity: 0.5,
            //     top: 3
            // },
            style: {
                colors: ["black"],
                fontSize: "18px",
                fontWeight: 300,
            },
        },
        stroke: {
            width: 1,
        },
        grid: {
            row: {
                colors: ["var(--primary)", "var(--weak-gray)"],
            },
        },
        xaxis: {
            type: "category",
            categories: nameValue.map((el) => el.name),
            labels: {
                rotate: -45,
                show: false,
                rotateAlways: false,
                hideOverlappingLabels: false,
                showDuplicates: true,
                minHeight: 0,
                maxHeight: 350,
                trim: false,
                style: {
                    colors: "var(--primaryDark)",
                    fontSize: "12px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        markers: {
            colors: diagramColors,
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    dataLabels: {
                        style: {
                            colors: ["#FFFFFF"],
                            fontSize: "13px",
                        },
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "80%",
                        },
                    },
                },
            },
            {
                breakpoint: 567,
                options: {
                    dataLabels: {
                        style: {
                            colors: ["#FFFFFF"],
                            fontSize: "10px",
                        },
                    },
                    xaxis: {
                        labels: {
                            show: false,
                            minHeight: 0,
                        },
                    },
                    yaxis: { labels: { show: false } },
                },
            },
        ],
        yaxis: {
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: "#008FFB",
            },
            labels: {
                style: {
                    colors: "#008FFB",
                },
            },
            title: {
                text: `${nameValue[0]?.data}`,
                style: {
                    color: "#008FFB",
                },
            },
            tooltip: {
                enabled: true,
            },
        },
        tooltip: {
            marker: {
                show: true,
                fillColors: diagramColors,
            },
            followCursor: true,
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                const value = nameValue?.[dataPointIndex]?.data
                const name = nameValue?.[dataPointIndex]?.name
                const total = data.totalPercentage || defaultTotal
                const percentage = ((value / total) * 100).toFixed(2)
                return (
                    `<div style="padding: 0px; max-width: 300px;">
                        <div 
                        style="padding: 5px 16px; background: #0A164F; max-width: 300px" >
                            <h3 style="font-weight: 500;
                                font-size: 14px; color: white; 
                                line-height: 14px; display: inline-block; word-wrap: break-word; text-align: start; white-space: pre-line; ">
                            ${title}
                            </h3>
                        </div>
                        <div style="background: white;display: flex; align-items: center; flex-wrap: wrap;
                        justify-content: space-between; width: 100%; gap: 6px; padding: 3px 16px;">
                            <div style="display: flex; align-items: center; gap: 6px;">
                                <span style="color: green; font-size: 30px; line-height">•</span>
                                <p style="text-align: start; margin-top: -10px; white-space: pre-line; word-wrap: break-word; color: black;">
                                ${name}
                                </p>
                            </div>
                            <div style="display: flex; align-items: center; gap: 6px; ">
                                <p style="font-weight: 500; font-size: 15px; color: black; word-wrap: white-space: pre-line; break-word;">
                                ${value.toLocaleString()}
                                </p> 
                                ${withPercentage ? `
                                    <span style="color: #0A164F">|</span>
                                <p style="font-weight: 500; color: #0A164F; font-size: 15px;">${percentage}%</p>` : ""}
                            </div>
                        </div>
                    <div>`
                )
            },
        },
    };
    return { options: options };
};
