// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__EpjJX {
  width: 50px;
  cursor: pointer;
  height: 50px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50% !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background: white;
  box-shadow: 0px 0px 18px 9px rgba(34, 60, 80, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/button-to-scroll/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,6BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,iBAAA;EAGA,kDAAA;AACJ","sourcesContent":[".button {\n    width: 50px;\n    cursor: pointer;\n    height: 50px;\n    position: fixed;\n    bottom: 10px;\n    right: 10px;\n    border-radius: 50% !important;\n    border: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 20;\n    background: white;\n    -webkit-box-shadow: 0px 0px 18px 9px rgba(34, 60, 80, 0.2);\n    -moz-box-shadow: 0px 0px 18px 9px rgba(34, 60, 80, 0.2);\n    box-shadow: 0px 0px 18px 9px rgba(34, 60, 80, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__EpjJX`
};
export default ___CSS_LOADER_EXPORT___;
