// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_item__7qgaD {
  transition: 350ms;
}
.styles_item__7qgaD:hover {
  background: var(--weak-white);
}`, "",{"version":3,"sources":["webpack://./src/components/menu-drop-down/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAAI;EACI,6BAAA;AAER","sourcesContent":[".item {\n    transition: 350ms;\n    &:hover {\n        background: var(--weak-white);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `styles_item__7qgaD`
};
export default ___CSS_LOADER_EXPORT___;
