import { Grid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useCallback, useState } from "react";
import { getTitleByLanguage } from "src/locales";
import { TreeStructureProps, coordinatesType } from "src/types";
import { StructureCard } from "./structure-card";
import { StructureGroup } from "./structure-group";
import { TreeLine } from "./tree-line";

export const TreeStructure = ({ data }: TreeStructureProps) => {
    const [coordinate, setCoordinate] = useState<coordinatesType>({
        parent: { x: null, y: null },
        children: [],
        subChildren: [],
    });
    const dataWithChildren = data?.children?.filter((el) => el.children);
    const mdScreen = useMediaQuery("(min-width: 992px)");

    const handleSetRootParent = useCallback((x: number, y: number) => {
        setCoordinate((prev) => ({
            parent: { x, y },
            children: [...prev.children],
            subChildren: [...prev.subChildren],
        }));
    }, []);

    const handleSetRootChildren = useCallback((x: number, y: number) => {
        setCoordinate((prev) => ({
            parent: { ...prev?.parent },
            children: [...(prev?.children || []), { x, y }],
            subChildren: [...prev.subChildren],
        }));
    }, []);

    const handleSetSubChildren = useCallback((x: number, y: number) => {
        setCoordinate((prev) => ({
            parent: { ...prev?.parent },
            children: [...(prev?.children || [])],
            subChildren: [...prev.subChildren, { x, y }],
        }));
    }, []);

    return (
        <Grid justify="center">
            <StructureCard
                span={{ base: 12, md: 5 }}
                title={getTitleByLanguage(data)}
                size={{ base: 22, md: 24, xl: 28 }}
                style={{ padding: "40px 20px" }}
                setCoordinate={handleSetRootParent}
            />
            {coordinate.children?.map((el, idx) => (
                <TreeLine
                    key={idx}
                    x1={coordinate.parent.x}
                    y1={coordinate.parent.y}
                    x2={el.x}
                    y2={coordinate.parent.y}
                    x3={el.x}
                    y3={el.y && mdScreen ? el.y - 100 : el.y}
                />
            ))}
            <Grid.Col mb={{ base: 0, md: 136 }}>
                <Grid justify="space-between">
                    {data?.children?.map(
                        (el, idx) =>
                            !el.children && (
                                <StructureCard
                                    key={idx}
                                    style={{ padding: "40px 20px" }}
                                    span={{ base: 12, md: 4, lg: 3 }}
                                    title={getTitleByLanguage(el)}
                                    size={{ base: 16, md: 18, xl: 22 }}
                                    setCoordinate={handleSetRootChildren}
                                />
                            )
                    )}
                </Grid>
            </Grid.Col>
            {coordinate.subChildren?.map((el, idx) => (
                <TreeLine
                    key={idx}
                    x1={coordinate.parent.x}
                    y1={coordinate.parent.y}
                    x2={coordinate.parent.x}
                    y2={el.y ? el.y - 250 : el.y}
                    x3={el.x}
                    y3={el.y ? el.y - 250 : el.y}
                    x4={el.x}
                    y4={el.y ? el.y - 200 : el.y}
                />
            ))}
            <Grid.Col>
                <Grid pos="relative" justify="space-around">
                    {Array.isArray(data?.children) &&
                        dataWithChildren?.map((el, idx) => (
                            <StructureGroup
                                span={{
                                    base: 12,
                                    md: 12 / dataWithChildren.length,
                                }}
                                key={idx}
                                setCoordinate={handleSetSubChildren}
                                data={el}
                            />
                        ))}
                </Grid>
            </Grid.Col>
        </Grid>
    );
};
