import {
    Box,
    Button,
    FileInput,
    Flex,
    Grid,
    Image,
    InputLabelProps,
    List,
    NumberInput,
    Paper,
    Text,
    TextInput,
    Textarea,
    Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { IconAsterisk, IconTrash } from "@tabler/icons-react";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppealListItem, CustomSelectOpen, PanelCards } from "src/components";
import { getTitleByLanguage } from "src/locales";
import {
    useCreateAppealMutation,
    useGetAllMapQuery,
    useOfficeSearchMutation,
} from "src/store";
import {
    getJsonFiles,
    notify,
    validatePhoneNumber,
} from "src/utils";

const labelStyles: InputLabelProps = {
    fz: "lg",
    fw: 400,
    c: "secondaryDark",
};

const defaultOffices = [
    {
        id: 2,
        titleKg: "Кыргыз Республикасынын Башкы прокуратурасы",
        titleRu: "Генеральная прокуратура Кыргызской Республики",
        email: "statement@prokuror.kg"
    },
    {
        id: 5,
        titleKg: "Кыргыз транспорттук прокуратурасы",
        titleRu: "Кыргызская транспортная прокуратура",
        email: "transport@prokuror.kg  "
    },
    {
        id: 4,
        titleKg: "Жазык-аткаруу тутумунун органдарында жана мекемелеринде мыйзамдардын сакталышын көзөмөлдөө прокуратурасы",
        titleRu: "Прокуратура по надзору за соблюдением законов в органах и учреждениях уголовно-исполнительной системы",
        email: "special@prokuror.kg"
    }
]


export const AppealInstruction = () => {
    const { t } = useTranslation();
    const [appealData, setAppealData] = useState([]);
    const middleScreen = useMediaQuery(`(max-width: 1200px)`);
    const [searchOffices, { data: offices }] = useOfficeSearchMutation();
    const [createAppeal] = useCreateAppealMutation();
    const { data: mapPoints } = useGetAllMapQuery({});
    const [activeRegion, setActiveRegion] = useState<number | undefined>(
        mapPoints?.[0]?.id
    );
    const form = useForm<AppealFormType>({
        initialValues: {
            prosecutorOfficeId: null,
            city: "",
            country: "",
            date: new Date()?.toISOString(),
            district: "",
            email: "",
            file: [],
            fullName: "",
            houseNumber: "Дом",
            id: null,
            phone: "+996",
            region: "",
            street: "",
            subjectOfTheAppeal: "",
            textOfTheAppeal: "",
        },
        validate: {
            region: (value) => !value && t("form.validation.required"),
            street: (value) => !value && t("form.validation.required"),
            textOfTheAppeal: (value) => !value && t("form.validation.required"),
            // email: (value: string) => validateEmail(value),
            fullName: (value: string) => !value && t("form.validation.required"),
            houseNumber: (value) => {
                if (typeof value === "number" && value < 1 && value > 1000)
                    return t`form.validation.numberOutOfRange`;
            },
            phone: (value: string) => {
                const result = validatePhoneNumber(value);
                if (result) return t(result);
                else return false
            },
        },
    });


    const getProsecutorOffices = async (mapPointId: number) => {
        try {
            await searchOffices({
                filter: { mapPointId: mapPointId },
                pageRequest: { limit: 30, page: 0 },
            });
        } catch (error) {
            console.error(
                `Error fetching prosecutor offices for map point ${mapPointId}`
            );
            return [];
        }
    };

    const handleChangeOffice = (value: string) => {
        const id = offices ? [...defaultOffices, ...offices?.content]?.find(
            (el) => el.titleRu === value || el.titleKg === value)?.id || 0 : 0;
        form.setFieldValue("prosecutorOfficeId", id);
    };
    const handleChangeRegion = (value: string) => {
        const id =
            mapPoints ? mapPoints?.find(
                (el) => el.cityOrRegionNameKg === value || el.cityOrRegionNameRu === value
            )?.id : undefined;
        setActiveRegion(id);
    };

    const mapPointsData = mapPoints?.map((el) =>
        getTitleByLanguage({
            titleKg: el.cityOrRegionNameKg,
            titleRu: el.cityOrRegionNameRu,
        })
    ) || [""];

    const officesData = offices ? [...defaultOffices, ...offices?.content]?.map((el) => getTitleByLanguage(el)) : [""];
    const activeMapPoint = mapPoints?.find((el) => el.id === activeRegion);
    const activeOffice = offices?.content ?
        [...defaultOffices, ...offices?.content]
            ?.find((el) => el.id === form.values.prosecutorOfficeId) : {
            titleKg: "",
            titleRu: "",
            email: "",
            id: 0,
        };
    const handleSubmit = async ({ file, ...body }: AppealFormType) => {
        try {
            await createAppeal({ file, body: { ...body } }).unwrap;
            notify(true, t("notify.sended"));
            form.reset();
        } catch (error) {
            notify(false);
        }
    };

    const handleChooseFile = (file: File[] | null) => {
        if (file) {
            form.setFieldValue(
                "file",
                form?.values?.file
                    ? [...form.values.file.filter(f => f !== null), ...file]
                    : [...file]
            );
        }
    }
    const handleDeleteFile = (index: number) => () => {
        const filtered = form.values.file?.filter((el, idx) => idx !== index) || []
        form.setFieldValue("file", filtered);
    }

    const handleChangePhone: ChangeEventHandler<HTMLInputElement> = (event) => {
        let value = event.target.value.replace(/\D/g, '');
        if (value.length < 13) {
            if (!value.startsWith('996')) {
                value = `+996${value}`;
                return;
            }
            const formattedValue = value.replace(/^996(\d{3})(\d{3})(\d{3})$/, '+996 $1 $2 $3');
            form.setFieldValue("phone", formattedValue);
        } else {
            form.setErrors({ phone: t("form.validation.has10Characters") })
        }
    };


    useEffect(() => {
        // getPersonnelCards();
        getJsonFiles("appeal-instruction").then((data) => {
            setAppealData(data);
        });
    }, []);

    useEffect(() => {
        if (activeRegion) {
            getProsecutorOffices(activeRegion);
        }
    }, [activeRegion]);

    useEffect(() => {
        if (mapPoints) {
            setActiveRegion(mapPoints[0]?.id);
        }
    }, [mapPoints]);

    return (
        <Flex
            style={{ overflow: "hidden" }}
            align={"start"}
            direction={{ base: "column", lg: "row" }}
            gap={32}
        >
            <Box mb={80}>
                <Title
                    mb={{ base: 14, md: 24 }}
                    c="primaryDark"
                    fz={{ base: 20, sm: 24, md: 30 }}
                >
                    {t("titles.appeal-instruction")}
                </Title>
                <Text
                    ta="justify"
                    mb={30}
                    c="primaryDark"
                    fz={{ base: "md", sm: "lg" }}
                >
                    {t("statics.appeal-information")}
                </Text>
                <List mb={40} type="ordered">
                    {appealData.map((el, idx) => (
                        <AppealListItem key={idx} item={el} />
                    ))}
                </List>
                <Box>
                    <Title
                        fz={{ base: 20, sm: 24, md: 30 }}
                        mb={{ base: 20, md: 30 }}
                        c="primaryDark"
                    >
                        {t("titles.online-appeal")}
                    </Title>
                    <form
                        onSubmit={form.onSubmit((values) => handleSubmit(values))}
                    >
                        <Grid mb={30} style={{ display: "flex", gap: "30px" }}>
                            <Grid.Col span={12} pos="relative">
                                <Text c="secondaryDark" fz={18}>
                                    {t("label.appeal-for-organ")}
                                </Text>
                                <CustomSelectOpen
                                    styles={{
                                        border: "1px solid var(--weak-gray)",
                                        boxShadow: "none",
                                    }}
                                    textLimit={500}
                                    bg="primary"
                                    dropdownBg="primaryDark"
                                    dropdownColor="primary"
                                    width="100%"
                                    dropdownWidth={middleScreen ? "90%" : "69%"}
                                    color="gray"
                                    data={mapPointsData}
                                    value={
                                        getTitleByLanguage({
                                            titleKg:
                                                activeMapPoint?.cityOrRegionNameKg,
                                            titleRu:
                                                activeMapPoint?.cityOrRegionNameRu,
                                        }) || ""
                                    }
                                    onChange={handleChangeRegion}
                                    label={t("form.label.choose")}
                                />
                            </Grid.Col>

                            <Grid.Col span={12} pos="relative">
                                <Text c="secondaryDark" fz={18}>
                                    {t("label.appeal-for-organ")}
                                </Text>
                                <CustomSelectOpen
                                    styles={{
                                        border: "1px solid var(--weak-gray)",
                                        boxShadow: "none",
                                    }}
                                    textLimit={500}
                                    bg="primary"
                                    dropdownBg="primaryDark"
                                    dropdownColor="primary"
                                    width="100%"
                                    dropdownWidth={middleScreen ? "90%" : "69%"}
                                    color="gray"
                                    data={officesData}
                                    value={
                                        getTitleByLanguage(activeOffice) === "-" ? "" : getTitleByLanguage(activeOffice)
                                    }
                                    onChange={handleChangeOffice}
                                    label={t("form.label.choose")}
                                />
                            </Grid.Col>
                            {(activeOffice?.email?.length || 1) > 3 ? (
                                <Grid.Col>
                                    <Title fw={400} lh={1} fz={18}>
                                        {t("form.label.email")}:{" "}
                                        {activeOffice?.email}
                                    </Title>
                                </Grid.Col>
                            ) : null}
                            <Grid.Col mb={30}>
                                <TextInput
                                    required
                                    withAsterisk
                                    labelProps={labelStyles}
                                    {...form.getInputProps("fullName")}
                                    label={t("label.fullName")}
                                    placeholder={t("label.fullName")}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <Flex>
                                    <Text fz="xl" fw={400} c="secondaryDark">
                                        {t("label.address")}
                                    </Text>
                                    <Text>
                                        <IconAsterisk size={8} color="red" />
                                    </Text>
                                </Flex>
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6 }}>
                                <TextInput
                                    required
                                    withAsterisk
                                    labelProps={labelStyles}
                                    {...form.getInputProps("region")}
                                    placeholder={t(
                                        "form.label.region-and-city"
                                    )}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6 }}>
                                <TextInput
                                    required
                                    withAsterisk
                                    labelProps={labelStyles}
                                    {...form.getInputProps("district")}
                                    placeholder={t(
                                        "form.label.district-village"
                                    )}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6 }}>
                                <TextInput
                                    required
                                    withAsterisk
                                    labelProps={labelStyles}
                                    {...form.getInputProps("street")}
                                    placeholder={t("form.label.street-complex")}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6 }}>
                                <NumberInput
                                    required
                                    withAsterisk
                                    rightSection={<span />}
                                    labelProps={labelStyles}
                                    {...form.getInputProps("houseNumber")}
                                    placeholder={t("form.label.houseNumber")}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <TextInput
                                    required
                                    withAsterisk
                                    type="email"
                                    labelProps={labelStyles}
                                    {...form.getInputProps("email")}
                                    label={t("label.email")}
                                    placeholder={t("form.label.email")}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <TextInput
                                    required
                                    withAsterisk
                                    labelProps={labelStyles}
                                    {...form.getInputProps("phone")}
                                    onChange={handleChangePhone}
                                    label={t("label.phone-number")}
                                    placeholder={t("form.label.phone")}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <Textarea
                                    required
                                    withAsterisk
                                    labelProps={labelStyles}
                                    label={t("label.appeal-text")}
                                    rows={10}
                                    {...form.getInputProps("textOfTheAppeal")}
                                    placeholder={t("label.appeal-text")}
                                />
                            </Grid.Col>
                        </Grid>
                        <Flex justify="space-between" align="start">
                            <Box>
                                <Box pos="relative" w={180} h={40}>
                                    <FileInput
                                        w={180}
                                        variant="unstyled"
                                        rightSectionWidth={140}
                                        {...form.getInputProps("file")}
                                        onChange={handleChooseFile}
                                        multiple
                                        style={{
                                            opacity: 0,
                                            position: "absolute",
                                            zIndex: 19,
                                        }}
                                    />
                                    <Flex wrap="wrap" align="center" pos="absolute" top={0}>
                                        <Image
                                            mr={10}
                                            width={20}
                                            src="/icons/attach-file.svg"
                                        />
                                        <Title c="primarydark" order={5}>
                                            {t("label.attach-file")}
                                        </Title>
                                    </Flex>
                                </Box>
                                <Flex wrap="wrap" maw={{ base: 300, md: 700 }} mt={10} gap={5}>
                                    {form.values.file?.map((item, index) => {
                                        let fileUrl = ""
                                        const type = item.type
                                        if (type.includes("image")) {
                                            fileUrl = URL.createObjectURL(item)
                                        } else {
                                            fileUrl = "/document.png"
                                        }
                                        return (
                                            <Paper px={5}>
                                                <Flex direction="column" align="center">
                                                    <Box style={{ cursor: "pointer" }}
                                                        onClick={handleDeleteFile(index)}
                                                        mb={-10}>
                                                        <IconTrash color="red" />
                                                    </Box>
                                                    <Image
                                                        w={100}
                                                        radius={"sm"}
                                                        key={index}
                                                        src={fileUrl}
                                                        m={"xs"}
                                                        fit="cover"
                                                        onLoad={() => URL.revokeObjectURL(fileUrl)}
                                                        onClick={() =>
                                                            window.open(URL.createObjectURL(item), "_blank")
                                                        }
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        alt=""
                                                    />
                                                    <Text>
                                                        {item.name.slice(0, 15)}...
                                                    </Text>
                                                </Flex>
                                            </Paper>
                                        )
                                    })}
                                </Flex>
                            </Box>
                            <Button
                                type="submit"
                                h="auto"
                                bg="primaryDark"
                                c="primary"
                                fz="md"
                                fw={500}
                                py={12}
                                px={45}
                            >
                                {t("statics.send")}
                            </Button>
                        </Flex>
                    </form>
                </Box>
            </Box>
            <PanelCards />
        </Flex>
    );
};
