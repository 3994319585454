import { Box } from "@mantine/core"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { IS_MINUS_ONE, diagramColors } from "src/constants"
import styles from "../../styles.module.scss"
import { BasicLineProgressItem } from "../line-progress-item"

type Props = {
    data: SearchDiagramResponseContentType;
    linkPath?: string;
}
export const HorizontalItem = ({ data, linkPath }: Props) => {
    const [focused, setFocused] = useState(IS_MINUS_ONE);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        let max = 0;
        data?.diagramFields?.forEach((el) => {
            const current = el.fieldValue;
            max = Math.max(max, current || 0);
        });
        setMaxValue(max);
    }, [data]);
    return (
        <Box component={Link} to={linkPath || ""} className={!linkPath ? "disable_click" : ""} px={{ base: 12, sm: 32 }}>
            {data?.diagramFields?.map(
                (item, idx) =>
                    idx < 7 && (
                        <BasicLineProgressItem
                            className={styles.progress_item}
                            focused={focused}
                            type="horizontal"
                            index={idx}
                            setFocused={setFocused}
                            maxValue={maxValue}
                            value={item.fieldValue || 0}
                            key={item.id}
                            item={item}
                            color={diagramColors[idx]}
                        />
                    )
            )}
        </Box>
    )
}
