import { Grid, StyleProp } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";
import { structureType } from "src/types";
import { StructureCard } from "./structure-card";

interface StructureGroupProps {
    data: structureType | undefined;
    setCoordinate: (x: number, y: number) => void;
    span: StyleProp<number | "auto" | "content">;
}

export const StructureGroup = ({
    data,
    setCoordinate,
    span,
}: StructureGroupProps) => {
    return (
        <Grid.Col span={span}>
            <StructureCard
                height={{ base: 140, sm: 200, xl: 170 }}
                title={getTitleByLanguage(data) || ""}
                size={{ base: 14, md: 16, xl: 20 }}
                style={{ padding: "30px 20px" }}
                setCoordinate={setCoordinate}
            />
            {data?.children?.map((childData) => (
                <StructureCard
                    key={childData.titleRu}
                    height={{ base: 130, md: "auto", xxl: 170 }}
                    size={{ base: 14, md: 16, xl: 18 }}
                    title={getTitleByLanguage(childData)}
                    style={{ padding: "30px 15px", marginBottom: "24px" }}
                />
            ))}
        </Grid.Col>
    );
};
