// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slider_item__De97l {
  z-index: 1;
  padding: 5rem 2.5rem;
  height: 300px;
  position: relative;
  justify-content: center;
  width: 90vw;
}

.styles_banner_btn__cxcLA {
  transition: 300ms;
  padding: 10px 16px 10px 24px;
}

.styles_banner_btn__cxcLA:hover {
  background: #c4c9e2 !important;
}

@media (max-width: 768px) {
  .styles_slider_item__De97l {
    padding: 10px 30px 30px;
  }
  .styles_slider_item_text__hj4RI {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/banner-card/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,oBAAA;EACA,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,WAAA;AACJ;;AACA;EACI,iBAAA;EACA,4BAAA;AAEJ;;AAAA;EACI,8BAAA;AAGJ;;AAAA;EACI;IACI,uBAAA;EAGN;EAFM;IACI,eAAA;EAIV;AACF","sourcesContent":[".slider_item {\n    z-index: 1;\n    padding: 5rem 2.5rem;\n    height: 300px;\n    position: relative;\n    justify-content: center;\n    width: 90vw;\n}\n.banner_btn {\n    transition: 300ms;\n    padding: 10px 16px 10px 24px;\n}\n.banner_btn:hover {\n    background: #c4c9e2 !important;\n}\n\n@media (max-width: 768px) {\n    .slider_item {\n        padding: 10px 30px 30px;\n        &_text {\n            font-size: 16px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider_item": `styles_slider_item__De97l`,
	"banner_btn": `styles_banner_btn__cxcLA`,
	"slider_item_text": `styles_slider_item_text__hj4RI`
};
export default ___CSS_LOADER_EXPORT___;
