// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_search_container__xJTY2 {
  transition: 350ms;
  width: 70%;
  max-width: 700px;
  z-index: -1;
  height: 65px;
}
.styles_search_container__xJTY2 .styles_search_input__2C-QN {
  width: 100%;
}
.styles_search_container__xJTY2 .styles_search_input__2C-QN input {
  background: none;
  color: var(--primary);
  border: none;
  font-size: 18px;
  border-bottom: 1px solid var(--primary);
  border-radius: 0px;
}
.styles_search_container__xJTY2 .styles_search_input__2C-QN input::placeholder {
  color: var(--primary);
}

@media (max-width: 1630px) {
  .styles_search_container__xJTY2 {
    width: 600px;
  }
}
@media (max-width: 1400px) {
  .styles_search_container__xJTY2 {
    width: 500px;
  }
}
@media (max-width: 1200px) {
  .styles_search_container__xJTY2 {
    width: 400px;
  }
}
@media (max-width: 860px) {
  .styles_search_container__xJTY2 {
    width: 350px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/search/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,UAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;AACJ;AAAI;EACI,WAAA;AAER;AADQ;EACI,gBAAA;EACA,qBAAA;EACA,YAAA;EACA,eAAA;EACA,uCAAA;EACA,kBAAA;AAGZ;AAFY;EACI,qBAAA;AAIhB;;AACA;EACI;IACI,YAAA;EAEN;AACF;AAAA;EACI;IACI,YAAA;EAEN;AACF;AAAA;EACI;IACI,YAAA;EAEN;AACF;AAAA;EACI;IACI,YAAA;EAEN;AACF","sourcesContent":[".search_container {\n    transition: 350ms;\n    width: 70%;\n    max-width: 700px;\n    z-index: -1;\n    height: 65px;\n    .search_input {\n        width: 100%;\n        input {\n            background: none;\n            color: var(--primary);\n            border: none;\n            font-size: 18px;\n            border-bottom: 1px solid var(--primary);\n            border-radius: 0px;\n            &::placeholder {\n                color: var(--primary);\n            }\n        }\n    }\n}\n@media (max-width: 1630px) {\n    .search_container {\n        width: 600px;\n    }\n}\n@media (max-width: 1400px) {\n    .search_container {\n        width: 500px;\n    }\n}\n@media (max-width: 1200px) {\n    .search_container {\n        width: 400px;\n    }\n}\n@media (max-width: 860px) {\n    .search_container {\n        width: 350px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_container": `styles_search_container__xJTY2`,
	"search_input": `styles_search_input__2C-QN`
};
export default ___CSS_LOADER_EXPORT___;
