import { Box } from "@mantine/core";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { baseUrl } from "src/constants";
import { Path, notify } from "src/utils";
import { CustomLoader } from "../custom-loader";

const getUrl = (id: number, uuid: string) =>
    `${baseUrl}/${Path.Attachments.get(id, uuid)}`;

interface IProps {
    item: {
        id: number;
        uuid: string;
    };
    docType?: string;
}

export const ShowFile = ({ item, docType = "pdf" }: IProps) => {
    const [isLoading, setLoading] = useState(false);
    const [file, setFile] = useState<string | undefined>(undefined);

    const handleDownlaod = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios(getUrl(item.id, item.uuid), {
                responseType: "arraybuffer",
            });
            const blob = new Blob([response.data], {
                type: `application/${docType}`,
            });
            const url = window.URL.createObjectURL(blob);
            setFile(url);
        } catch {
            notify();
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        handleDownlaod();
    }, []);
    return (
        <>
            {isLoading ?
                <Box pos="relative" w="100%" mih="40vh" h="100%">
                    <CustomLoader />
                </Box> :
                <embed
                    src={file}
                    type="application/pdf"
                    width="80vw"
                    style={{
                        maxWidth: "700px",
                        width: "80vw",
                        border: "none",
                        minHeight: "100vh",
                    }}
                    title="file"
                />
            }
        </>
    );
};
