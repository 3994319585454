export * from "./about";
export * from "./appeal/";
export * from "./contacts/contacts";
export * from "./home/links";
export * from "./home/news";
export * from "./inter-coop";
export * from "./laws";
export * from "./news";
export * from './news/panel-news';
export * from "./personnel";
export * from "./personnel/accordion";
export * from "./personnel/list";
export * from "./press-service";
export * from "./prosecutor-100-year";
export * from "./protect-bussiness";
export * from "./statistic";
export * from "./statistic/main-statistic";
export * from "./statistic/secondary-statistic";
