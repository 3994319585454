import { Accordion, List, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";
import { personnelItem } from "src/types";

interface RulesItemProps {
    item: personnelItem;
}

export const AccordionItem = ({ item }: RulesItemProps) => {
    const { i18n } = useTranslation();
    return (
        <Accordion.Item key={item.titleRu} value={item.titleRu}>
            <Accordion.Control>
                <Title fz={{ base: "sm", md: "md", sm: "lg" }}>
                    {getTitleByLanguage(item)}
                </Title>
            </Accordion.Control>
            <Accordion.Panel>
                <List type="ordered">
                    {i18n.language === "ru"
                        ? item?.itemsRu?.map((el) => (
                            <ListItem key={el} item={el} />
                        ))
                        : item?.itemsKg?.map((el) => (
                            <ListItem key={el} item={el} />
                        ))}
                </List>
            </Accordion.Panel>
        </Accordion.Item>
    );
};

const ListItem = ({ item }: { item: string }) => (
    <List.Item>
        <Text c="primaryDark" fz={{ base: 12, sm: 16 }} lh={2} fw={400}>
            {item}
        </Text>
    </List.Item>
);
