// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  scrollbar-width: thin;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  --primary-dark: 10, 22, 79;
  --primary: #ffffff;
  --primary-rgba: 255, 255, 255;
  --secondary-dark: #0b1f7c;
  --secondary: #101828;
  --gray: #555f71;
  --weak-gray: rgba(11, 31, 124, 0.2);
  --weak-white: rgba(255, 255, 255, 0.04);
  --light-blue: #1ab1e0;
  --soft-blue: #106f9e;
  --oceon-blue: #187fdb;
  --weak-blue: rgba(7, 21, 87, 0.5);
  --weak-dark: #1a1c19;
  --borders: #c4c9e2;
  --inactive: #c4c4c4;
  --_popover-bg: black !important;
  --primaryDark: rgba(10, 22, 79, 1);
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAAA;EACI,wDAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,gCAAA;EACA,oBAAA;EACA,kCAAA;EACA,mCAAA;EACA,kCAAA;EACA,8BAAA;EACA,SAAA;EACA,UAAA;EACA,0BAAA;EACA,kBAAA;EACA,6BAAA;EACA,yBAAA;EACA,oBAAA;EACA,eAAA;EACA,mCAAA;EACA,uCAAA;EACA,qBAAA;EACA,oBAAA;EACA,qBAAA;EACA,iCAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,+BAAA;EACA,kCAAA;AACJ","sourcesContent":[":root {\n    font-family: Inter, Avenir, Helvetica, Arial, sans-serif;\n    font-size: 16px;\n    line-height: 24px;\n    font-weight: 400;\n    scrollbar-width: thin;\n    color-scheme: light dark;\n    color: rgba(255, 255, 255, 0.87);\n    font-synthesis: none;\n    text-rendering: optimizeLegibility;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-text-size-adjust: 100%;\n    margin: 0;\n    padding: 0;\n    --primary-dark: 10, 22, 79;\n    --primary: #ffffff;\n    --primary-rgba: 255, 255, 255;\n    --secondary-dark: #0b1f7c;\n    --secondary: #101828;\n    --gray: #555f71;\n    --weak-gray: rgba(11, 31, 124, 0.2);\n    --weak-white: rgba(255, 255, 255, 0.04);\n    --light-blue: #1ab1e0;\n    --soft-blue: #106f9e;\n    --oceon-blue: #187fdb;\n    --weak-blue: rgba(7, 21, 87, 0.5);\n    --weak-dark: #1a1c19;\n    --borders: #c4c9e2;\n    --inactive: #c4c4c4;\n    --_popover-bg: black !important;\n    --primaryDark: rgba(10, 22, 79, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
