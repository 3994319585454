import { useMediaQuery } from "@mantine/hooks";
import ReactApexChart from "react-apexcharts";
import { VALUES_SHOW_TYPES } from "src/constants";
import { generateColOptions } from "../options/generate-col-options";

export const VerticalChart = ({ data }) => {
    const withPercentage =
        data?.textEn !== VALUES_SHOW_TYPES.WITHOUT_PERCENTAGE;
    const { options } = generateColOptions(data, withPercentage);
    const mobileScreen = useMediaQuery("(max-width: 576px)");
    return (
        <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            height={mobileScreen ? 450 : 600}
        />
    );
};
