// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_accordion_item__p0\\+Bt {
  border-bottom: none !important;
  padding: 0px 10px;
}`, "",{"version":3,"sources":["webpack://./src/modules/open-part/statistic/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,iBAAA;AACJ","sourcesContent":[".accordion_item {\n    border-bottom: none !important;\n    padding: 0px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion_item": `styles_accordion_item__p0+Bt`
};
export default ___CSS_LOADER_EXPORT___;
