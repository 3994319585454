// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__JDH3i {
  position: fixed;
  top: 0;
  height: 90px;
  width: 100vw;
  background: var(--primaryDark);
  z-index: 15;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_header__JDH3i .styles_logo_container__PQoAm {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -60%);
}

@media (max-width: 676px) {
  .styles_logo_container__PQoAm {
    transform: translate(-50%, -72%) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/header/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,YAAA;EACA,YAAA;EACA,8BAAA;EACA,WAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAAI;EACI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,gCAAA;AAER;;AAEA;EACI;IACI,2CAAA;EACN;AACF","sourcesContent":[".header {\n    position: fixed;\n    top: 0;\n    height: 90px;\n    width: 100vw;\n    background: var(--primaryDark);\n    z-index: 15;\n    padding: 5px 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    .logo_container {\n        position: absolute;\n        top: 100%;\n        left: 50%;\n        transform: translate(-50%, -60%);\n    }\n}\n\n@media (max-width: 676px) {\n    .logo_container {\n        transform: translate(-50%, -72%) !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__JDH3i`,
	"logo_container": `styles_logo_container__PQoAm`
};
export default ___CSS_LOADER_EXPORT___;
