// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_nav_title__B0BvO {
  color: var(--primary) !important;
  transition: 300ms;
}
.styles_nav_title__B0BvO:hover {
  color: rgba(255, 255, 255, 0.6) !important;
}

.styles_menu_item__wZH3u {
  width: 350px;
  margin-right: 50px;
  border-bottom: none !important;
}

@media (max-width: 992px) {
  .styles_menu_item__wZH3u {
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .styles_menu_item__wZH3u {
    margin-right: 0px;
  }
}
@media (max-width: 370px) {
  .styles_menu_item__wZH3u {
    width: 280px;
    margin-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/list/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,iBAAA;AACJ;AAAI;EACI,0CAAA;AAER;;AACA;EACI,YAAA;EACA,kBAAA;EACA,8BAAA;AAEJ;;AAAA;EACI;IACI,kBAAA;EAGN;AACF;AADA;EACI;IACI,iBAAA;EAGN;AACF;AAAA;EACI;IACI,YAAA;IACA,eAAA;EAEN;AACF","sourcesContent":[".nav_title {\n    color: var(--primary) !important;\n    transition: 300ms;\n    &:hover {\n        color: rgba(255, 255, 255, 0.6) !important;\n    }\n}\n.menu_item {\n    width: 350px;\n    margin-right: 50px;\n    border-bottom: none !important;\n}\n@media (max-width: 992px) {\n    .menu_item {\n        margin-right: 30px;\n    }\n}\n@media (max-width: 768px) {\n    .menu_item {\n        margin-right: 0px;\n    }\n}\n\n@media (max-width: 370px) {\n    .menu_item {\n        width: 280px;\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav_title": `styles_nav_title__B0BvO`,
	"menu_item": `styles_menu_item__wZH3u`
};
export default ___CSS_LOADER_EXPORT___;
