import { Box } from "@mantine/core";

export const FilterBack = ({
    opacity = 0.5,
    className = "",
}: {
    opacity?: number;
    className?: string;
}) => {
    return (
        <Box
            className={className}
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            w="100%"
            h="100%"
            bg="primaryDark"
            opacity={opacity}
            style={{ zIndex: "-1", transition: "300ms" }}
        />
    );
};
