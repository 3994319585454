import { Grid, Title } from "@mantine/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from "react-i18next";
import { diagramColors } from "src/constants";
import { getTitleByLanguage } from "src/locales";

type Props = {
    data: SearchDiagramResponseContentType
    linkPath?: string
}
export const PieItem = ({ data, linkPath }: Props) => {
    const { i18n } = useTranslation()
    const options = {
        credits: { enabled: false },
        title: {
            text: ""
        },
        legend: {
            enabled: false
        },
        tooltip: {
            valueDecimals: 2,
            valueSuffix: ''
        },
        accessibility: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                colorByPoint: true,
                type: 'pie',
                size: '100%',
                innerSize: '70%',
                series: {

                },
                dataLabels: {
                    enabled: false,
                    crop: false,
                    distance: '-10%',
                    style: {
                        fontWeight: '300',
                        fontSize: '12px'
                    },
                    connectorWidth: 0
                }
            }
        },
        colors: diagramColors,
        series: [{
            type: "pie",
            name: getTitleByLanguage(data),
            data: data?.diagramFields?.map(el => ([
                getTitleByLanguage(el),
                el.fieldValue
            ]
            ))
        }]
    }

    return (
        <Grid justify="space-evenly" align="center" px={{ base: 12, sm: 32 }} >
            <Grid.Col span={{ base: 12, lg: 6 }}>
                <a href={linkPath} className={linkPath ? "" : "disable_click"}>
                    {data?.diagramFields?.map((item, index) => index < 7 && (
                        <Grid key={item.id} w="100%">
                            {i18n.language === "ru" ?
                                <Grid.Col span={{ base: 12, lg: 6 }}>
                                    <Grid>
                                        <Grid.Col span={{ base: 8, md: 10 }}>
                                            <Title fz={{ base: 14, md: 16 }} c="secondary" lh={1} order={4} fw="400">
                                                {getTitleByLanguage(item)}
                                            </Title>
                                        </Grid.Col>
                                        <Grid.Col span={{ base: 4, sm: 2 }}>
                                            <Title order={3} fw="400" c="primaryDark">
                                                {item.fieldValue?.toLocaleString()}
                                            </Title>
                                        </Grid.Col>
                                    </Grid>
                                </Grid.Col> :
                                <Grid.Col span={{ base: 12, lg: 6 }}>
                                    <Grid>
                                        <Grid.Col span={{ base: 8, md: 10 }}>
                                            <Title fz={{ base: 14, md: 16 }} c="secondary" lh={1} order={4} fw="400">
                                                {getTitleByLanguage(item)}
                                            </Title>
                                        </Grid.Col>
                                        <Grid.Col span={{ base: 4, sm: 2 }}>
                                            <Title order={3} fw="400" c="primaryDark">
                                                {item.fieldValue?.toLocaleString()}
                                            </Title>
                                        </Grid.Col>
                                    </Grid>
                                </Grid.Col>}
                        </Grid>
                    ))}
                </a>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 4 }}>
                <HighchartsReact
                    allowChartUpdate={true}
                    options={options}
                    highcharts={Highcharts} />
            </Grid.Col>
        </Grid>
    )
}
