import { Carousel } from "@mantine/carousel";
import { Center, Flex, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DIAGRAM_TYPES } from "src/constants";
import { getTitleByLanguage } from "src/locales";
import { ClassicItem, HorizontalItem, PieItem, RadiusPieItem, VerticalItem } from "./chart-types";

export const StatisticCarouselItem = ({
    data,
}: {
    data: SearchDiagramResponseContentType;
}) => {
    const { i18n } = useTranslation()
    const phoneScreen = useMediaQuery("(max-width: 500px)")
    const linkPath = `/statistics/${i18n.language}?quarter=${data.diagramQuarter}&year=${data.year}&categoryId=${data?.id}`
    const getComponent = (type?: string) => {
        switch (type) {
            case DIAGRAM_TYPES.CLASSIC:
                return <ClassicItem linkPath={linkPath} data={data} />
            case DIAGRAM_TYPES.RADIUS_PIE:
                return <RadiusPieItem linkPath={linkPath} data={data} />
            case DIAGRAM_TYPES.VERTICAL_BAR:
                return phoneScreen ? <HorizontalItem linkPath={linkPath} data={data} /> : <VerticalItem linkPath={linkPath} data={data} />
            case DIAGRAM_TYPES.HORIZONTAL_BAR:
                return <HorizontalItem linkPath={linkPath} data={data} />
            case DIAGRAM_TYPES.PIE:
                return <PieItem linkPath={linkPath} data={data} />
            default:
                return <ClassicItem linkPath={linkPath} data={data} />
        }
    }

    return (
        <Carousel.Slide w="100%" mb={10}>
            <Flex

                h="100%" direction="column">
                <Center w={{ base: "95%", md: "80%" }} ta="center" mx="auto">
                    <Link to={linkPath}>
                        <Title
                            lh={1.3}
                            fz={{ base: 18, sm: 24, md: 28 }}
                            c="primaryDark"
                            ta="center"
                        >
                            {getTitleByLanguage(data)}
                        </Title>
                    </Link>
                </Center>
                <Flex direction="column"
                    my={20} mb={{ base: 30, md: 0 }}
                    w="100%"
                    justify="center" h="100%" >
                    {getComponent(data.type)}
                </Flex>
            </Flex>
        </Carousel.Slide>
    );
};