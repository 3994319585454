import { Grid, Progress, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { getTitleByLanguage } from "src/locales";

interface ProgressItemProps {
    item: DiagramFieldsResponseType;
    color: string;
    className: string;
    setFocused: (a: number) => void;
    value: number;
    maxValue: number;
    index: number;
    focused: number;
    type?: "horizontal" | "classic"
}

export const BasicLineProgressItem = ({
    item,
    color,
    className,
    setFocused,
    focused,
    maxValue,
    value,
    index,
    type = "classic",
}: ProgressItemProps) => {
    const fieldValue = item?.fieldValue;
    const percentage = (value ? value / maxValue : 35) * 100;
    const { i18n } = useTranslation()
    return (
        <Grid
            onMouseMove={() => setFocused(item?.id || 0)}
            onMouseLeave={() => setFocused(-1)}
            className={className}
            align="center"
            w="100%"
            maw={type === "classic" ? 1200 : undefined}
        >
            {type === "classic" ?
                <>
                    <Grid.Col mb={{ base: -30, md: 0 }} span={{ base: 12, md: 2 }}>
                        <Title order={3} fw="400" c="primaryDark">
                            {fieldValue?.toLocaleString()}
                        </Title>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 5 }}>
                        <Title fz={{ base: 16, md: 18 }}
                            c="secondary"
                            lh={1} order={4} fw="400">
                            {getTitleByLanguage(item)}
                        </Title>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4.5 }}>
                        <Progress
                            transitionDuration={300}
                            translate="yes"
                            radius={10} h={13}
                            value={percentage} color={color} />
                    </Grid.Col>
                </>
                :
                <>

                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <Progress
                            transitionDuration={300}
                            translate="yes"
                            radius={10} h={13}
                            value={percentage} color={color} />
                    </Grid.Col>
                    {i18n.language === "kg" ?
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Grid justify="space-between">
                                <Grid.Col mb={{ base: -30, md: 0 }} span={{ base: 12, md: 2 }}>
                                    <Title order={3} fw="400" c="primaryDark">
                                        {fieldValue?.toLocaleString()}
                                    </Title>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 10 }}>
                                    <Title fz={{ base: 16, md: 18 }}
                                        c="secondary"
                                        lh={1} order={4} fw="400">
                                        {getTitleByLanguage(item)}
                                    </Title>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                        :
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Grid justify="space-between">
                                <Grid.Col span={{ base: 8, md: 10 }}>
                                    <Title fz={{ base: 12, sm: 16, md: 18 }}
                                        c="secondary"
                                        lh={1} order={4} fw="400">
                                        {getTitleByLanguage(item)}
                                    </Title>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <Title order={3} fw="400" c="primaryDark">
                                        {fieldValue?.toLocaleString()}
                                    </Title>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                    }
                </>
            }
        </Grid>
    );
};
