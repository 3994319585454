import { Card, Flex, Grid, Image, Text, Title } from "@mantine/core";
import { getTitleByLanguage } from "src/locales";

interface PersonnelCardProps {
    person: CardType;
}

export const ProsecutorOfficeCardInfo = ({ person }: PersonnelCardProps) => {
    return (
        <Grid.Col
            style={{ display: "flex", justifyContent: "center" }}
            span={{ base: 12, sm: 6, lg: 3, xxl: 2.4 }}
            mb={24}
        >
            <Card style={{ borderRadius: "2px" }} p={24} w={310} h={530}>
                <Flex direction="column" align="center" gap={12} w="100%">
                    {person?.photo ? (
                        <Flex w={260} h={350} style={{ overflow: "hidden" }}>
                            <Image
                                width={260}
                                height={350}
                                src={`data:image/webp;base64,${person?.photo}`}
                                style={{
                                    objectFit: "cover",
                                    objectPosition: "50% 50%",
                                }}
                                alt=""
                            />
                        </Flex>
                    ) : (
                        <Flex
                            align="center"
                            justify="center"
                            bg="primaryDark"
                            w={260}
                            h={350}
                        >
                            <Image src="/icons/logo.svg" />
                        </Flex>
                    )}
                    <Flex
                        style={{ wordBreak: "break-word" }}
                        w="100%"
                        gap={0}
                        direction="column"
                    >
                        <Text
                            h={38}
                            fz={
                                person?.position?.titleRu?.length > 80
                                    ? 11
                                    : person?.position?.titleRu?.length > 50
                                        ? 14
                                        : 16
                            }
                            c="weakPrimaryDark"
                            lh={1.2}
                        >
                            {getTitleByLanguage(person?.position)}
                        </Text>
                        <Title
                            lh={1}
                            fz={
                                person?.fullName?.titleRu?.length > 40 ? 18 : 20
                            }
                            c="secondaryDark"
                        >
                            {getTitleByLanguage(person?.fullName)}
                        </Title>
                    </Flex>
                </Flex>
            </Card>
        </Grid.Col>
    );
};
