import { apiSlice } from "src/store/apiSlice";
import { Path } from "src/utils";

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createUser: builder.mutation<IUser, UserCreateRequest>({
            query: (body) => ({
                url: Path.Users.create,
                method: "POST",
                body,
            })
        }),
        updateUser: builder.mutation<
            unknown,
            { id: number; body: Partial<UserForm> }
        >({
            query: ({ id, body }) => {

                return {
                    url: Path.Users.update(id),
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: ["Users"],
        }),
        getUser: builder.query<IUser, string | number>({
            query: (id) => ({
                url: Path.Users.get(id),
            }),
            providesTags: ["Users"],
        }),
        userDelete: builder.mutation({
            query: (id) => ({
                url: Path.Users.get(id),
                method: "DELETE",
            }),
            invalidatesTags: ["Users"],
        }),
        searchUsers: builder.mutation<UserSearchResponse, UserSearchRequest>({
            query: (body) => ({
                url: Path.Users.search,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Users"],
        }),

        enableUser: builder.mutation<unknown, string | number>({
            query: (id) => ({
                url: Path.Users.enable(id),
                method: "GET",
            }),
        }),
        disableUser: builder.mutation<unknown, string | number>({
            query: (id) => ({
                url: Path.Users.disable(id),
                method: "DELETE",
            }),
        }),

        getRoles: builder.query<LocaleTitle[], unknown>({
            query: (id) => ({
                url: Path.Users.getRoles,
            }),
            providesTags: ["Users"],
        }),

        getPermissions: builder.query<LocaleTitle[], unknown>({
            query: () => ({
                url: Path.Users.getPermissions,
            }),
            providesTags: ["Users"],
        }),
    }),
});

export const {
    useCreateUserMutation,
    useGetUserQuery,
    useUpdateUserMutation,
    useUserDeleteMutation,
    useSearchUsersMutation,
    useGetPermissionsQuery,
    useGetRolesQuery,
    useDisableUserMutation,
    useEnableUserMutation
} = usersApiSlice;
