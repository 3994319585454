// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_item__MndAV {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0px;
}
.styles_item_title__NZKiK {
  transition: 300ms;
  gap: 5px;
  align-items: center;
}
.styles_item__MndAV:hover .styles_item_title__NZKiK {
  opacity: 0.5;
}

.styles_item_light__Kftrw {
  font-size: 16px !important;
  font-weight: 400;
  border-radius: 0 !important;
  padding: 5px 10px !important;
}
.styles_item_light_title__mQYjP {
  transition: 300ms;
  align-items: center;
  gap: 5px;
}
.styles_item_light__Kftrw:hover .styles_item_light_title__mQYjP {
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/custom-select/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAAI;EACI,iBAAA;EACA,QAAA;EACA,mBAAA;AAER;AAAI;EACI,YAAA;AAER;;AACA;EACI,0BAAA;EACA,gBAAA;EACA,2BAAA;EACA,4BAAA;AAEJ;AADI;EACI,iBAAA;EACA,mBAAA;EACA,QAAA;AAGR;AADI;EACI,YAAA;AAGR","sourcesContent":[".item {\n    margin: 10px 0;\n    font-size: 16px;\n    font-weight: 400;\n    border-radius: 0px;\n    &_title {\n        transition: 300ms;\n        gap: 5px;\n        align-items: center;\n    }\n    &:hover &_title {\n        opacity: 0.5;\n    }\n}\n.item_light {\n    font-size: 16px !important;\n    font-weight: 400;\n    border-radius: 0 !important;\n    padding: 5px 10px !important;\n    &_title {\n        transition: 300ms;\n        align-items: center;\n        gap: 5px;\n    }\n    &:hover &_title {\n        opacity: 0.6;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `styles_item__MndAV`,
	"item_title": `styles_item_title__NZKiK`,
	"item_light": `styles_item_light__Kftrw`,
	"item_light_title": `styles_item_light_title__mQYjP`
};
export default ___CSS_LOADER_EXPORT___;
