// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_main_wrapper__0RTlM {
  color: var(--primary);
  overflow: hidden;
}
.styles_main_wrapper__0RTlM .styles_title__G8Obu {
  font-size: 15rem;
  font-family: "Playfair", serif;
  font-weight: 700;
}
.styles_main_wrapper__0RTlM .styles_parallax_container__uZCK0 {
  width: 100%;
  height: 100vh;
  perspective: 1px;
  overflow-y: auto;
}
.styles_main_wrapper__0RTlM .styles_parallax_item__douD6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/parallax-layer/styles.module.scss"],"names":[],"mappings":"AAAA;EAEI,qBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,gBAAA;EACA,8BAAA;EACA,gBAAA;AAAR;AAEI;EACI,WAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AAAR;AAEI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AAAR","sourcesContent":[".main_wrapper {\n    //height: 400vh;\n    color: var(--primary);\n    overflow: hidden;\n\n    .title {\n        font-size: 15rem;\n        font-family: \"Playfair\", serif;\n        font-weight: 700;\n    }\n    .parallax_container {\n        width: 100%;\n        height: 100vh;\n        perspective: 1px;\n        overflow-y: auto;\n    }\n    .parallax_item {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        will-change: transform;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_wrapper": `styles_main_wrapper__0RTlM`,
	"title": `styles_title__G8Obu`,
	"parallax_container": `styles_parallax_container__uZCK0`,
	"parallax_item": `styles_parallax_item__douD6`
};
export default ___CSS_LOADER_EXPORT___;
