import { Button, Card, Image, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
    IconArrowBigLeft,
    IconArrowBigRight,
    IconStarFilled,
} from "@tabler/icons-react";
import { FC, useEffect, useState } from "react";
import { useLazyGetPhotoQuery } from "src/store";

type CardWithPhotoPropsType = {
    data: PhotoType;
    photos: PhotoType[];
};

export const CardWithPhoto: FC<CardWithPhotoPropsType> = ({ data, photos }) => {
    const [opened, { close, open }] = useDisclosure(false);
    const [modalId, setModalId] = useState<number>(data.id);
    const [getPhoto, { data: photo }] = useLazyGetPhotoQuery();
    const photoIndex = photos.findIndex((photo) => photo.id === modalId);

    useEffect(() => {
        getPhoto(modalId).unwrap();
    }, [getPhoto, modalId]);

    const nextIdFromIndex = (index: number) => {
        let i = index % photos.length;

        while (photos[i].id === modalId) {
            i = (i + 1) % photos.length;
        }

        setModalId(photos[i].id);
    };

    const prevIdFromIndex = (index: number) => {
        let i = index < 0 ? photos.length - 1 : index % photos.length;

        while (photos[i].id === modalId) {
            i = i === 0 ? photos.length - 1 : i - 1;
        }

        setModalId(photos[i].id);
    };

    return (
        <Card
            shadow="sm"
            p="lg"
            radius="md"
            withBorder
            mah={230}
            style={{
                position: "relative",
                cursor: "pointer",
                "&:hover": {
                    transition: "all 0.3s ease-in-out",
                    transform: "scale(1.05)",
                    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                },
            }}
        >
            <Card.Section>
                {data.main && (
                    <IconStarFilled
                        style={{
                            position: "absolute",
                            top: 10,
                            left: 10,
                            zIndex: 1,
                            color: "gold",
                        }}
                        size={24}
                    />
                )}
                <Image
                    src={`data:image/webp;  base64,${data?.miniature}`}
                    height={"100%"}
                    alt={"Ошибка загрузки фото"}
                    fit="cover"
                    onClick={() => {
                        open();
                        setModalId(data.id);
                    }}
                />
            </Card.Section>
            <Modal opened={opened} onClose={close} size="auto" m={0} p={0}>
                <Button
                    onClick={() => prevIdFromIndex(photoIndex - 1)}
                    variant="subtle"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: 20,
                        zIndex: 1,
                        height: 40,
                        backgroundColor: "rgb(37, 38, 43)",
                        borderRadius: "50%",
                    }}
                >
                    {<IconArrowBigLeft size={24} color="white" />}
                </Button>
                <Image
                    src={`data:image/webp;base64,${photo?.original}`}
                    height={"80vh"}
                    alt={"Ошибка загрузки фото"}
                    fit="cover"
                />
                <Button
                    onClick={() => nextIdFromIndex(photoIndex + 1)}
                    variant="subtle"
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: 20,
                        zIndex: 1,
                        height: 40,
                        backgroundColor: "rgb(37, 38, 43)",
                        borderRadius: "50%",
                    }}
                >
                    {<IconArrowBigRight size={24} color="white" />}
                </Button>
            </Modal>
        </Card>
    );
};
