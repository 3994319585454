// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_big_news_title__dNWGd {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/modules/open-part/home/news/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;AACJ","sourcesContent":[".big_news_title {\n    display: -webkit-box;\n    -webkit-line-clamp: 8;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"big_news_title": `styles_big_news_title__dNWGd`
};
export default ___CSS_LOADER_EXPORT___;
