import { Carousel } from "@mantine/carousel";
import { Box } from "@mantine/core";
import { BannerCard, CustomLoader } from "src/components";
import { useGetAllBannersQuery } from "src/store";

export const BannerSection = () => {
    const { data: banners, isLoading } = useGetAllBannersQuery([], {
        refetchOnMountOrArgChange: false,
    });

    return (
        <Box pos="relative">
            <Carousel
                withIndicators={true}
                withControls={false}
                align="start"
                height={300}
                loop={true}
            >
                {isLoading ? (
                    <Box pos="relative" w='100%' h={300}>
                        <CustomLoader />
                    </Box>
                ) : (
                    banners?.map((item, idx) => (
                        <BannerCard key={idx} banner={item} idx={idx} />
                    ))
                )}
            </Carousel>
        </Box>
    );
};
