// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_links_group__ZHFFS .styles_links_item__QdLgp {
  transition: 350ms;
}
.styles_links_group__ZHFFS .styles_links_item__QdLgp .styles_img_container__Z4CvY {
  overflow: hidden;
}
.styles_links_group__ZHFFS .styles_links_item_filter__ME93i {
  opacity: 0.3;
  z-index: 2 !important;
}
.styles_links_group__ZHFFS .styles_links_item_title__3kzgU {
  z-index: 3;
}

.styles_social_link_icon__KVFBz {
  transition: 300ms;
}

.styles_links_item__QdLgp:hover .styles_links_item_filter__ME93i {
  opacity: 0.8 !important;
}

.styles_bg_image__p3kM1 {
  background-position: 100% center !important;
  background-size: 95% auto !important;
  background-repeat: no-repeat;
  transition: 350ms;
  cursor: pointer;
}
.styles_bg_image__p3kM1:hover {
  background-position: calc(100% + 140px) center !important;
  background-size: 125% auto !important;
}
.styles_bg_image__p3kM1:hover .styles_social_link_icon__KVFBz {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}`, "",{"version":3,"sources":["webpack://./src/modules/open-part/home/links/styles.module.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;AAAR;AACQ;EACI,gBAAA;AACZ;AACQ;EACI,YAAA;EACA,qBAAA;AACZ;AACQ;EACI,UAAA;AACZ;;AAGA;EACI,iBAAA;AAAJ;;AAEA;EACI,uBAAA;AACJ;;AACA;EACI,2CAAA;EACA,oCAAA;EACA,4BAAA;EACA,iBAAA;EACA,eAAA;AAEJ;AADI;EACI,yDAAA;EACA,qCAAA;AAGR;AADI;EACI,qDAAA;AAGR","sourcesContent":[".links_group {\n    & .links_item {\n        transition: 350ms;\n        .img_container {\n            overflow: hidden;\n        }\n        &_filter {\n            opacity: 0.3;\n            z-index: 2 !important;\n        }\n        &_title {\n            z-index: 3;\n        }\n    }\n}\n.social_link_icon {\n    transition: 300ms;\n}\n.links_item:hover .links_item_filter {\n    opacity: 0.8 !important;\n}\n.bg_image {\n    background-position: calc(100%) center !important;\n    background-size: 95% auto !important;\n    background-repeat: no-repeat;\n    transition: 350ms;\n    cursor: pointer;\n    &:hover {\n        background-position: calc(100% + 140px) center !important;\n        background-size: 125% auto !important;\n    }\n    &:hover .social_link_icon {\n        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links_group": `styles_links_group__ZHFFS`,
	"links_item": `styles_links_item__QdLgp`,
	"img_container": `styles_img_container__Z4CvY`,
	"links_item_filter": `styles_links_item_filter__ME93i`,
	"links_item_title": `styles_links_item_title__3kzgU`,
	"social_link_icon": `styles_social_link_icon__KVFBz`,
	"bg_image": `styles_bg_image__p3kM1`
};
export default ___CSS_LOADER_EXPORT___;
